import React, { Component } from 'react'
import { Typography, Tabs, Button } from 'antd';

import logo from '../../images/schedio_short_logo.png'


const { Title } = Typography;
const { TabPane } = Tabs;

export class HelpPage extends Component {
    render() {
        return (
            <div style={{ padding: "20px" }}>


                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>

                    <div>
                        <Title className="c1 c8" id="h.p7lsja9gmiv3">Βοήθεια - Εγχειρίδιο Χρήσης</Title>
                        <Button type="primary" shape="round" href="https://rtm-api.xyz/api/misc/docs/0/" icon="download"> Λήψη Εγχειριδίου Χρήσης</Button>
                    </div>
                    <img id="logo" alt="logo" src={logo} style={{ maxWidth: "80px", height: "auto", marginRight: "20px" }}></img>

                </div>


                <Tabs defaultActiveKey="1">

                    <TabPane tab="Γενικά" key="1">

                        <p className="c1 c3"><span className="c4" /></p>
                        <Title level={4} className="c17 c8" id="h.n49mx4dbxxwa"><span className="c13 c6">Τι είναι
                        διαδικασία;</span></Title>
                        <p className="c1"><span className="c4">Είναι ένα
                        σύνολο ενεργειών
                        με καθορισμένη τάξη
                        που αφορούν σε
                        κάποιο σκοπό.</span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <br />
                        <Title level={4} className="c27 c8" id="h.8vj9h6p71win"><span className="c6">Τι είναι </span><span className="c6 c23">διαδικασία</span><span className="c13 c6">&nbsp;στο Schedio;</span></Title>
                        <p className="c5"><span className="c4">Αποτελεί μία
                        οντότητα με ένα
                        σύνολο βημάτων που
                        αποσκοπούν σε ένα
                        τελικό στόχο. Το Schedio
                        έχει
                        προ-σχεδιασμένες
                        ερευνητικές
                        διαδικασίες που
                        μπορεί να ξεκινήσει
                        ο ΕΥ με
                        αποτέλεσμα να έχει
                        πλήρη επίγνωση και
                        έλεγχο σε αυτές.</span>
                        </p>
                        <p className="c5"><span className="c4">Π.χ. Έναρξη
                        Σύμβασης
                        Εργασίας</span></p>
                        <p className="c5"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '111.46px', height: '73.08px' }}><img alt="" src={require('../../images/help_images/image61.png')} style={{ width: '111.46px', height: '73.08px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c5"><span className="c10 c25">Σύμβολο
                        διαδικασίας</span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <br />
                        <Title level={4} className="c17 c8" id="h.qr6ohsdtqzfe"><span className="c13 c6">Τι είναι
                        έργο;</span></Title>
                        <p className="c1"><span className="c4">Οποιοδήποτε
                        ανθρώπινο
                        δημιούργημα,
                        αποτέλεσμα της
                        εργασίας.</span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <br />
                        <Title level={4} className="c17 c8" id="h.er6zgkfnv4a0"><span className="c6">Τι είναι </span><span className="c6 c29">έργο </span><span className="c13 c6">στο Schedio;</span>
                        </Title>
                        <p className="c5"><span className="c10">Έργο είναι ένα
                        σύνολο από </span><span className="c28">διαδικασίες</span><span className="c21">. </span><span className="c4">Στην ουσία
                            είναι ένας τρόπος
                            ομαδοποίησης και
                            κατηγοριοποίησης
                            διαδικασιών από τον
                        χρήστη.</span></p>
                        <p className="c5"><span className="c4">Π.χ. Ερευνητικό
                        πρότζεκτ “ΧΧΧ” (το
                        οποίο θα μπορούσε
                        να περιέχει πολλές
                        διαδικασίες)</span></p>
                        <p className="c5 c3"><span className="c4" /></p>
                        <p className="c5"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '86.46px', height: '86.46px' }}><img alt="" src={require('../../images/help_images/image66.png')} style={{ width: '86.46px', height: '86.46px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c5"><span className="c10 c22 c25">Σύμβολο
                        έργου</span></p>
                        <br />
                        <Title level={4} className="c17 c8" id="h.wkktgsxzqiz0"><span className="c13 c6">Τι είναι
                        το Dashboard;</span></Title>
                        <p className="c1"><span className="c4">Η ενότητα dashboard, που
                        μπορεί να βρεθεί
                        στο menu, αποτελεί το
                        κέντρο ελέγχου
                        του ΕΥ.</span></p>
                        <p className="c1"><span className="c4">Εκεί μπορεί να
                        προσθέσει, να
                        μοιραστεί, να
                        παρακολουθήσει
                        και να
                        διαχειριστεί
                        διαδικασίες.</span></p>
                        <p className="c1"><span className="c4">Τα μέρη του dashboard
                        αναφέρονται
                        αναλυτικότερα
                        παρακάτω.</span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c1 c3"><span className="c4" /></p>


                    </TabPane>


                    <TabPane tab="Διαδικασία" key="2">

                        <Title level={4} className="c17 c8" id="h.fj5ek39vzdx8"><span className="c13 c6">Πως μπορώ
                        να προσθέσω μία
                        διαδικασία;</span></Title>
                        <p className="c1 c3"><span className="c14 c6" /></p>
                        <p className="c1"><span className="c4">Με την
                        δημιουργία ενός
                        νέου λογαριασμού,
                        το dashboard είναι άδειο.
                        Για να προσθέσετε
                        μία από τις
                        προ-σχεδιασμένες
                        διαδικασίες
                        ακολουθήστε τα
                        εξής βήματα: </span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <ol className="c16 lst-kix_r844nboo02j5-0 start" start={1}>
                            <li className="c1 c7 c2"><span className="c10">Πατήστε το
                            κουμπί “Προσθήκη”
                            στο πάνω αριστερά
                        μέρος του dashboard.</span>
                                <br />
                                <span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '399.89px', height: '265.50px' }}><img alt="" src={require('../../images/help_images/image13.png')} style={{ width: '399.89px', height: '265.50px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></li>
                            <li className="c1 c7 c2"><span className="c4">Επιλέξτε το
                            κουμπί
                        “Διαδικασία”.</span></li>
                        </ol>
                        <p className="c1 c2"><span style={{ overflow: 'hidden', display: 'inline-block', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '450.04px', height: '300.00px' }}><img alt="" src={require('../../images/help_images/image9.png')} style={{ width: '500.00px', height: '300.00px', marginLeft: '-16.96px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c3 c2"><span className="c4" /></p>
                        <p className="c1 c3 c2"><span className="c4" /></p>
                        <ol className="c16 lst-kix_r844nboo02j5-0" start={3}>
                            <li className="c1 c7 c2"><span className="c4">Συμπληρώστε
                            τα απαραίτητα
                            στοιχεία της
                        διαδικασίας</span>
                                <ul className="c16 lst-kix_r844nboo02j5-1 start" start={1}>
                                    <li className="c1 c12 c7"><span className="c4">Τίτλος (Πως
                                    θέλει χρήστης να
                                    εμφανίζεται αυτή η
                                διαδικασία)</span></li>
                                    <li className="c1 c12 c7"><span className="c4">Τα email των χρηστών
                                    με τους οποίους
                                    θέλετε να
                                    μοιραστείτε αυτή
                                τη διαδικασία</span></li>
                                    <li className="c1 c12 c7"><span className="c4">Την
                                    προ-σχεδιασμένη
                                    διαδικασία
                                (*απαραίτητο)</span></li>
                                    <li className="c1 c12 c7"><span className="c4">Επιλέξτε σε
                                    ποιο έργο θέλετε
                                    να ανήκει η
                                    διαδικασία
                                    (μπορεί να μην ανήκει
                                σε κανένα)</span></li>
                                </ul>
                            </li>
                        </ol>
                        <p className="c1 c2"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '176.46px', height: '403.34px' }}><img alt="" src={require('../../images/help_images/image64.png')} style={{ width: '176.46px', height: '403.34px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <ol className="c16 lst-kix_r844nboo02j5-0" start={4}>
                            <li className="c1 c7 c2"><span className="c10">Τέλος
                            πατήστε το κουμπί
                            της
                        “Αποθήκευσης”.</span>
                                <hr style={{ pageBreakBefore: 'always', display: 'none' }} />
                            </li>
                        </ol><br />
                        <Title level={4} className="c17 c8" id="h.pt1famc9y1mv"><span className="c13 c6">Που μπορώ
                        να βρω τις
                        διαδικασίες που
                        πρόσθεσα;</span></Title>
                        <p className="c1 c3"><span className="c14 c6" /></p>
                        <p className="c1"><span className="c10">Θα καταλάβετε
                        ότι όλα πήγαν καλά
                        αν η </span><span className="c24">Διαδικασία </span><span className="c10">που μόλις
                            προσθέσατε
                        εμφανιστεί στην </span><span className="c18 c6">λίστα
                        διαδικασιών </span><span className="c4">του dashboard.</span></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '602.00px', height: '313.33px' }}><img alt="" src={require('../../images/help_images/image47.png')} style={{ width: '602.00px', height: '313.33px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c1"><span className="c10">Η </span><span className="c18 c6">λίστα
                        διαδικασιών</span><span className="c4">&nbsp;του χρήστη
                            βρίσκεται κεντρικά
                            στο dashboard, δεξιά από τα
                            στατιστικά και την
                        λίστα έργων.</span></p>
                        <p className="c5"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '353.46px', height: '382.15px' }}><img alt="" src={require('../../images/help_images/image2.jpg')} style={{ width: '799.34px', height: '392.44px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <hr style={{ pageBreakBefore: 'always', display: 'none' }} />
                        <p className="c5 c3"><span className="c4" /></p>
                        <p className="c5"><span className="c4">Ας
                        αναλύσουμε μία
                        διαδικασία, όπως
                        προβάλλεται στην
                        λίστα
                        διαδικασιών.</span></p>
                        <p className="c5 c3"><span className="c4" /></p>
                        <ul className="c16 lst-kix_u74m12u66u1-0 start">
                            <li className="c5 c7 c2"><span className="c4">Μία
                            διαδικασία είναι
                            επιλεγμένη όταν
                            έχει γαλάζιο
                        πλαίσιο.</span></li>
                        </ul>
                        <p className="c5 c2"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '399.00px', height: '115.00px' }}><img alt="" src={require('../../images/help_images/image41.png')} style={{ width: '399.00px', height: '115.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c5 c2"><span className="c15 c22">Επιλεγμένη
                        Διαδικασία</span></p>
                        <p className="c5 c3 c2"><span className="c4" /></p>
                        <p className="c5 c2"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '401.00px', height: '95.00px' }}><img alt="" src={require('../../images/help_images/image17.png')} style={{ width: '401.00px', height: '95.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c5 c2"><span className="c15 c22">Μη-Επιλεγμένη
                        Διαδικασία</span></p>
                        <p className="c5 c3 c2"><span className="c15 c22" /></p>
                        <ul className="c16 lst-kix_8h464yyxo5n4-0 start">
                            <li className="c5 c7 c2"><span className="c4">Πάνω
                            εμφανίζεται ο
                            τίτλος που έχει
                            δώσει ο χρήστης σε
                            αυτή τη
                        διαδικασία.</span></li>
                        </ul>
                        <p className="c1 c19"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '399.00px', height: '115.00px' }}><img alt="" src={require('../../images/help_images/image55.png')} style={{ width: '399.00px', height: '115.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <ul className="c16 lst-kix_3utqgjg9r0os-0 start">
                            <li className="c1 c7 c2"><span className="c4">Στην μέση
                            εμφανίζεται ο
                            αναγνωριστικός
                            κωδικός της
                            διαδικασίας
                            (κάθε διαδικασία,
                            κάθε χρήστη στο Schedio
                            έχει ξεχωριστό
                        κωδικό)</span></li>
                        </ul>
                        <p className="c1 c2"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '399.00px', height: '115.00px' }}><img alt="" src={require('../../images/help_images/image25.png')} style={{ width: '399.00px', height: '115.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <ul className="c16 lst-kix_3utqgjg9r0os-0">
                            <li className="c1 c7 c2"><span className="c4">Στο κάτω
                            μέρος βρίσκεται η
                            πρόοδος της
                            επιλεγμένης
                            διαδικασίας σε
                        ποσοστό.</span></li>
                        </ul>
                        <p className="c1 c2"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '399.00px', height: '115.00px' }}><img alt="" src={require('../../images/help_images/image45.png')} style={{ width: '399.00px', height: '115.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c3 c2"><span className="c4" /></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <br />
                        <Title level={4} className="c17 c8" id="h.u3s240pnpgqd"><span className="c13 c6">Πρόσθεσα
                        την διαδικασία
                        επιτυχώς, τι κάνω
                        τώρα;</span></Title>
                        <p className="c1"><span className="c10">Αν επιλέξετε
                        μία διαδικασία από
                        την λίστα
                        διαδικασιών θα
                        παρατηρήσετε ότι
                        στο δεξί μέρος του
                        dashboard θα εμφανιστούν οι
                    </span><span className="c6 c18">λεπτομέρειες
                        της διαδικασίας</span><span className="c4">.</span></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '478.00px', height: '292.00px' }}><img alt="" src={require('../../images/help_images/image2.jpg')} style={{ width: '623.95px', height: '305.00px', marginLeft: '-145.95px', marginTop: '-6.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1"><span className="c4">Στο πάνω μέρος
                        θα παρατηρήσουμε
                        τις βασικές
                        πληροφορίες της
                        επιλεγμένης
                        διαδικασίες.</span></p>
                        <p className="c1"><span className="c4">Πληροφορίες
                        όπως :</span></p>
                        <ol className="c16 lst-kix_r10edxyctmb5-0 start" start={1}>
                            <li className="c1 c7 c2"><span className="c4">Τίτλος (Πως
                            θέλει χρήστης να
                            εμφανίζεται αυτή η
                        διαδικασία)</span></li>
                            <li className="c1 c7 c2"><span className="c4">Αναγνωριστικός
                            κωδικός (όπως και
                            στην λίστα
                        διαδικασιών)</span></li>
                            <li className="c1 c7 c2"><span className="c4">Πρόοδος σε
                        ποσοστό</span></li>
                            <li className="c1 c7 c2"><span className="c4">Ημερομηνία
                            και ώρα έναρξης
                        διαδικασίας</span></li>
                        </ol>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c1"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '462.96px', height: '92.00px' }}><img alt="" src={require('../../images/help_images/image63.png')} style={{ width: '624.00px', height: '92.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <hr style={{ pageBreakBefore: 'always', display: 'none' }} />
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c1"><span className="c4">Καθώς και 3
                        κουμπιά με τις
                        παρακάτω
                        ενέργειες :</span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <ul className="c16 lst-kix_xrnxb87fet3e-0 start">
                            <li className="c1 c7 c2"><span className="c14 c6">Επεξεργασία
                        Διαδικασίας</span></li>
                        </ul>
                        <p className="c1 c2"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '461.96px', height: '92.00px' }}><img alt="" src={require('../../images/help_images/image37.png')} style={{ width: '624.00px', height: '92.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c2"><span className="c4">Ανοίγει το
                        παράθυρο
                        επεξεργασίας των
                        στοιχείων της
                        επιλεγμένης
                        διαδικασίας το
                        οποίο είναι
                        πανομοιότυπο με
                        αυτό της
                        έναρξης/προσθήκης
                        διαδικασίας.</span></p>
                        <p className="c9 c2"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '326.46px', height: '461.42px' }}><img alt="" src={require('../../images/help_images/image16.png')} style={{ width: '326.46px', height: '461.42px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <hr style={{ pageBreakBefore: 'always', display: 'none' }} />
                        <p className="c1 c3 c2"><span className="c4" /></p>
                        <ul className="c16 lst-kix_tn9uz6ngxi2w-0 start">
                            <li className="c1 c7 c2"><span className="c14 c6">Προβολή
                        Έργου</span></li>
                        </ul>
                        <p className="c1 c2"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '461.96px', height: '92.00px' }}><img alt="" src={require('../../images/help_images/image39.png')} style={{ width: '624.00px', height: '92.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c2"><span className="c4">Ανοίγει το
                        παράθυρο προβολής
                        των στοιχείων του
                        έργου (περισσότερα
                        για αυτό παρακάτω)</span>
                        </p>
                        <p className="c9 c2"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '410.46px', height: '412.00px' }}><img alt="" src={require('../../images/help_images/image1.png')} style={{ width: '410.46px', height: '412.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c3 c2"><span className="c4" /></p>
                        <p className="c1 c3 c2"><span className="c4" /></p>
                        <ul className="c16 lst-kix_6it3zlgx7pj8-0 start">
                            <li className="c1 c7 c2"><span className="c14 c6">Αρχειοθέτηση
                        Διαδικασίας</span></li>
                        </ul>
                        <p className="c1 c2"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '602.00px', height: '90.67px' }}><img alt="" src={require('../../images/help_images/image38.png')} style={{ width: '602.00px', height: '90.67px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c3 c2"><span className="c14 c6" /></p>
                        <p className="c1 c2"><span className="c4">Το κουμπί αυτό
                        αρχειοθετεί την
                        επιλεγμένη
                        διαδικασία με
                        δυνατότητα
                        επαναφοράς
                        μεταγενέστερα.</span></p>
                        <p className="c1 c3 c2"><span className="c4" /></p>
                        <p className="c1 c2"><span className="c4">Οι
                        αρχειοθετημενες
                        διαδικασίες
                        είναι διαθέσιμες
                        για προβολή και
                        επαναφορά στην
                        λίστα διαδικασιών.
                        Για να τις
                        εμφανίσετε
                        ενεργοποιήστε την
                        επιλογή “Εμφάνιση
                        Αρχειοθετημένων
                        Διαδικασιών” στο
                        αριστερό μέρος του
                        dashboard.</span></p>
                        <p className="c1 c3 c2"><span className="c4" /></p>
                        <p className="c1 c19"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '282.63px', height: '209.50px' }}><img alt="" src={require('../../images/help_images/image53.png')} style={{ width: '282.63px', height: '209.50px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '282.46px', height: '209.27px' }}><img alt="" src={require('../../images/help_images/image21.png')} style={{ width: '282.46px', height: '209.27px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c5 c19"><span className="c10">Μπορείτε να
                        ξεχωρίσετε μία
                        αρχειοθετημένη
                        διαδικασία από το
                        σύμβολο</span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '25.73px', height: '24.50px' }}><img alt="" src={require('../../images/help_images/image34.png')} style={{ width: '25.73px', height: '24.50px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span className="c4">.</span></p>
                        <p className="c9 c3"><span className="c4" /></p>
                        <p className="c5 c19"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '497.00px', height: '139.00px' }}><img alt="" src={require('../../images/help_images/image50.png')} style={{ width: '497.00px', height: '139.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c9 c3"><span className="c4" /></p>
                        <p className="c5 c2"><span className="c4">Μπορείτε να
                        επαναφέρετε μία
                        αρχειοθετημένη
                        διαδικασία
                        πατώντας το κουμπί
                        “Επαναφορά
                        Διαδικασίας” πάνω
                        δεξιά.</span></p>
                        <p className="c5 c19"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '602.00px', height: '92.00px' }}><img alt="" src={require('../../images/help_images/image26.png')} style={{ width: '602.00px', height: '92.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <hr style={{ pageBreakBefore: 'always', display: 'none' }} />
                        <p className="c9 c3"><span className="c4" /></p>
                        <br />
                        <Title level={4} className="c17 c8" id="h.sqo88mqhipy6"><span className="c13 c6">Πως μπορώ
                        να προχωρήσω βήματα
                        στην Διαδικασία;</span></Title>
                        <p className="c1"><span className="c4">Το επόμενο
                        πλαίσιο στις
                        Λεπτομέρειες
                        Διαδικασίας αφορά
                        την διαχείριση
                        βημάτων της
                        διαδικασίας.</span></p>
                        <p className="c1"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '415.00px', height: '285.00px' }}><img alt="" src={require('../../images/help_images/image5.png')} style={{ width: '415.00px', height: '285.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c1"><span className="c4">Στο πλαίσιο
                        αυτό αναφέρονται
                        σημαντικές
                        πληροφορίες για
                        την επόμενη
                        ενέργεια που
                        χρειάζεται να
                        γίνει ώστε να
                        προχωρήσει η
                        επιλεγμένη
                        διαδικασία.</span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c1"><span className="c4">Στην περιοχή
                        αυτή εμφανίζεται ο
                        τίτλος και η
                        περιγραφή του
                        βήματος.</span></p>
                        <p className="c1"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '415.00px', height: '285.00px' }}><img alt="" src={require('../../images/help_images/image35.png')} style={{ width: '415.00px', height: '285.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span>
                        </p><hr style={{ pageBreakBefore: 'always', display: 'none' }} />
                        <p />
                        <p className="c1"><span className="c4">Κάποια βήματα
                        εμπεριέχουν
                        υπο-βήματα που η
                        ολοκλήρωση τους
                        είναι απαραίτητη
                        για να μπορεί να
                        προχωρήσει η
                        επιλεγμένη
                        διαδικασία.</span></p>
                        <p className="c1"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '411.00px', height: '453.00px' }}><img alt="" src={require('../../images/help_images/image11.png')} style={{ width: '411.00px', height: '453.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c1"><span className="c4">Στο πλαίσιο
                        αυτό μπορούν να
                        πραγματοποιηθούν
                        οι εξής
                        ενέργειες:</span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <ul className="c16 lst-kix_ho8c6id7erph-0 start">
                            <li className="c1 c7 c2"><span className="c14 c6">“Επόμενο”</span>
                                <ul className="c16 lst-kix_ho8c6id7erph-1 start">
                                    <li className="c1 c12 c7"><span className="c4">Πατώντας το
                                    κουμπί ενώ
                                    βρίσκεστε στο
                                    τωρινό βήμα, θέτει
                                    το βήμα αυτό ως
                                    ολοκληρωμένο και
                                    προβάλλει το
                        επόμενο.</span></li>
                                    <li className="c1 c12 c7"><span className="c4">Πατώντας το
                                    κουμπί ενώ είστε
                                    σε ολοκληρωμένο
                                    βήμα, προβάλλει το
                        επόμενο.</span></li>
                                </ul>
                            </li>
                        </ul>
                        <p className="c1 c12"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '308.71px', height: '212.50px' }}><img alt="" src={require('../../images/help_images/image18.png')} style={{ width: '308.71px', height: '212.50px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c12"><span className="c15 c22">Ένα
                        ολοκληρωμένο βήμα
                        έχει ένα πράσινο τικ
                        δεξιά της
                        ονομασίας του.</span></p>
                        <ul className="c16 lst-kix_xr8m5qke7fjx-0 start">
                            <li className="c1 c7 c2"><span className="c14 c6">“Προηγούμενο”</span>
                                <ul className="c16 lst-kix_xr8m5qke7fjx-1 start">
                                    <li className="c1 c12 c7"><span className="c4">Προβάλλει
                                    το προηγούμενο
                                    βήμα από το παρόν
                        προβαλλόμενο.</span></li>
                                </ul>
                            </li>
                        </ul>
                        <p className="c1 c12"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '336.46px', height: '247.51px' }}><img alt="" src={require('../../images/help_images/image33.png')} style={{ width: '336.46px', height: '247.51px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c12 c3"><span className="c4" /></p>
                        <ul className="c16 lst-kix_w58myx4bg4u7-0 start">
                            <li className="c1 c7 c2"><span className="c14 c6">“Επιστροφή
                        στο τωρινό βήμα”</span>
                                <ul className="c16 lst-kix_w58myx4bg4u7-1 start">
                                    <li className="c1 c7 c12"><span className="c4">Ενώ
                                    προβάλλεται ένα
                                    ολοκληρωμένο
                                    βήμα,
                                    ενεργοποιείται η
                                    ενέργεια για
                                    γρήγορη επιστροφή
                        στο τωρινό βήμα.</span></li>
                                </ul></li>
                        </ul>
                        <p className="c1 c12"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '394.46px', height: '255.75px' }}><img alt="" src={require('../../images/help_images/image42.png')} style={{ width: '394.46px', height: '255.75px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span>
                        </p><hr style={{ pageBreakBefore: 'always', display: 'none' }} />
                        <p />
                        <ul className="c16 lst-kix_eggra12oykgr-0 start">
                            <li className="c1 c7 c2"><span className="c14 c6">“Προσθήκη
                        Προθεσμίας”</span>
                                <ul className="c16 lst-kix_eggra12oykgr-1 start">
                                    <li className="c1 c12 c7"><span className="c10">Σε κάθε βήμα
                                    και κάθε υπο-βήμα
                                    υπάρχει η
                                    δυνατότητα
                                    υποβολής
                                    προθεσμίας
                                    ολοκλήρωσης.
                                    Πατώντας το
                        σύμβολο </span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '26.50px', height: '26.50px' }}><img alt="" src={require('../../images/help_images/image15.png')} style={{ width: '26.50px', height: '26.50px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span className="c10">&nbsp;με το
                                        “Ημερολόγιο”,
                                        δίπλα από την
                                        ονομασία του
                                        βήματος, θα
                                        ανοίξει το
                                        παράθυρο
                                        υποβολής
                                        προθεσμίας
                                        ολοκλήρωσης. Αν
                                        υπάρχει ήδη
                                        ορισμένη
                                        προθεσμία, το
                                        σύμβολο αυτό θα
                        είναι μπλε </span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '25.86px', height: '25.86px' }}><img alt="" src={require('../../images/help_images/image49.png')} style={{ width: '25.86px', height: '25.86px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span className="c4">. Επιλέξτε
                                        την ημερομηνία που
                                        θέλετε και
                                        πατήστε
                        “Επιβεβαίωση”.</span></li>
                                </ul>
                            </li>
                        </ul>
                        <p className="c1 c12"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '338.46px', height: '348.98px' }}><img alt="" src={require('../../images/help_images/image29.png')} style={{ width: '338.46px', height: '348.98px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c12 c3"><span className="c4" /></p>
                        <ul className="c16 lst-kix_yzwod5o9ona-0 start">
                            <li className="c1 c7 c2"><span className="c14 c6">“Προσθήκη
                        Σημείωσης”</span>
                                <ul className="c16 lst-kix_yzwod5o9ona-1 start">
                                    <li className="c1 c12 c7"><span className="c10">Υπάρχει
                                    δυνατότητα
                                    προσθήκης
                                    σημείωσης ανα βήμα
                                    πατώντας το
                        σύμβολο </span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '28.03px', height: '28.99px' }}><img alt="" src={require('../../images/help_images/image20.png')} style={{ width: '28.03px', height: '28.99px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span className="c10">&nbsp;πάνω δεξιά
                                        στο πλαίσιο. Η
                                        σημείωση αυτή θα
                                        υπάρχει στο βήμα
                                        της συγκεκριμένης
                                        διαδικασίας, για
                        τον χρήστη </span><span className="c10">όποτε</span><span className="c4">&nbsp;ξεκινάει τη
                        διαδικασία αυτή.</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p className="c1 c12"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '405.46px', height: '209.29px' }}><img alt="" src={require('../../images/help_images/image57.png')} style={{ width: '405.46px', height: '209.29px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <br />
                        <Title level={4} className="c17 c8" id="h.chdfduooia0e"><span className="c13 c6">Πως μπορώ
                        να δω το
                        χρονοδιάγραμμα της
                        Διαδικασίας;</span></Title>
                        <p className="c1"><span className="c4">Στα δεξιά του
                        πλαισίου των
                        λεπτομερειών
                        διαδικασίας
                        υπάρχει το πλαίσιο
                        του
                        χρονοδιαγράμματος.
                        Στο πλαίσιο αυτό
                        μπορούμε να δούμε
                        σε χρονολογική
                        σειρά όλα τα βήματα
                        της διαδικασίας
                        που χρειάζεται να
                        υλοποιηθούν ώστε
                        να ολοκληρωθεί.</span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '470.17px', height: '396.48px' }}><img alt="" src={require('../../images/help_images/image8.png')} style={{ width: '470.17px', height: '396.48px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c1"><span className="c14 c6">Σύμβολα</span></p>
                        <p className="c1"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '28.00px', height: '20.00px' }}><img alt="" src={require('../../images/help_images/image24.png')} style={{ width: '28.00px', height: '20.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span className="c4">Ολοκληρωμένο
                        βήμα (πράσινο χρώμα)</span></p>
                        <p className="c1"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '24.00px', height: '20.00px' }}><img alt="" src={require('../../images/help_images/image36.png')} style={{ width: '24.00px', height: '20.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span className="c4">Τωρινό βήμα
                        (μπλε χρώμα)</span></p>
                        <p className="c1"><span className="c10">&nbsp;</span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '18.00px', height: '21.00px' }}><img alt="" src={require('../../images/help_images/image48.png')} style={{ width: '18.00px', height: '21.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span className="c4">&nbsp;Μη
                        ολοκληρωμένο/μελλοντικό
                        βήμα (γκρι χρώμα)</span></p>
                        <p className="c1"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '23.00px', height: '23.00px' }}><img alt="" src={require('../../images/help_images/image3.png')} style={{ width: '23.00px', height: '23.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span className="c4">&nbsp;Τωρινό βήμα
                        με ορισμένη
                        προθεσμία</span></p>
                        <hr style={{ pageBreakBefore: 'always', display: 'none' }} />
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c1"><span className="c6 c14">Λεπτομερές
                        Χρονοδιάγραμμα</span></p>
                        <p className="c1"><span className="c4">Πατώντας το
                        κουμπί “Προβολή
                        Λεπτομερειών” στο
                        κάτω μέρος του
                        πλαισίου του
                        χρονοδιαγράμματος,
                        θα ανοίξει το
                        Λεπτομερές
                        Χρονοδιάγραμμα. Το
                        χρονοδιάγραμμα
                        αυτό εμπεριέχει
                        όλα τα υπο-βήματα
                        των τωρινών ή
                        ολοκληρωμένων
                        βημάτων καθώς και
                        ημερομηνίες
                        ολοκλήρωσης
                        κάθε βήματος.</span></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '360.46px', height: '398.66px' }}><img alt="" src={require('../../images/help_images/image62.png')} style={{ width: '360.46px', height: '398.66px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c9 c3"><span className="c4" /></p>
                        <hr style={{ pageBreakBefore: 'always', display: 'none' }} />
                        <h3 className="c27 c8 c30" id="h.2xj4tqsl0nbp"><span className="c13 c6" /></h3>
                        <br />
                        <Title level={4} className="c27 c8" id="h.ntzh81wybued"><span className="c13 c6">Μπορώ να
                        ανεβάσω έγγραφα
                        και
                        δικαιολογητικά
                        σχετιζόμενα με την
                        Διαδικασία;</span></Title>
                        <p className="c1"><span className="c4">Στο πλαίσιο
                        των λεπτομερειών
                        διαδικασίας
                        μπορείτε να βρείτε
                        την ενότητα των
                        Δικαιολογητικών,
                        κάτω αριστερά. Στην
                        ενότητα αυτή σας
                        επιτρέπεται να
                        ανεβάσετε αρχεία
                        και &nbsp;έγγραφα
                        σχετιζόμενα με την
                        επιλεγμένη
                        διαδικασία.</span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '433.46px', height: '364.69px' }}><img alt="" src={require('../../images/help_images/image22.png')} style={{ width: '433.46px', height: '364.69px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c9 c3"><span className="c4" /></p>
                        <p className="c5"><span className="c10">Τα
                        δικαιολογητικά
                        χωρίζονται σε </span><span className="c18 c6">Δικαιολογητικά
                        Διαδικασίας </span><span className="c10">και </span><span className="c18 c6">Δικαιολογητικά
                        Βήματος.</span><span className="c4">&nbsp;Η
                            διαφορά τους
                            είναι ότι στην
                            επιλογή
                            δικαιολογητικά
                            διαδικασίας
                            κατατάσσονται όλα
                            τα αρχεία που
                            έχουν ανέβει σε
                            αυτή τη διαδικασία,
                            ενώ στα
                            δικαιολογητικά
                            βήματος
                            κατατάσσονται όσα
                            αρχεία έχουν
                            συσχετιστεί με το
                            παρόν βήμα.(Αυτό που
                            προβάλλεται στο
                            πλαίσιο του
                        Τωρινού Βήματος)</span></p>
                        <p className="c5 c3"><span className="c4" /></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '288.46px', height: '151.71px' }}><img alt="" src={require('../../images/help_images/image12.png')} style={{ width: '288.46px', height: '151.71px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c9"><span className="c15 c22">Όλα τα αρχεία
                        της επιλεγμένης
                        διαδικασίας</span></p>
                        <p className="c9 c3"><span className="c15 c22" /></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '298.46px', height: '121.87px' }}><img alt="" src={require('../../images/help_images/image51.png')} style={{ width: '298.46px', height: '121.87px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c9"><span className="c15 c22">Τα αρχεία
                        σχετιζόμενα με το
                        επιλεγμένο βήμα
                        της διαδικασίας</span></p>
                        <p className="c9 c3"><span className="c15 c22" /></p>
                        <p className="c9 c3"><span className="c4" /></p>
                        <p className="c5"><span className="c4">Για να
                        συσχετισεται ένα
                        αρχείο με κάποιο
                        βήμα, το μόνο που
                        χρειάζεται είναι
                        κατά το ανέβασμα
                        του αρχείου, στο
                        πλαίσιο του
                        Τωρινού Βήματος να
                        εμφανίζεται το
                        επιθυμητό βήμα.</span></p>
                        <p className="c5 c3"><span className="c4" /></p>
                        <p className="c5"><span className="c14 c6" style={{ fontWeight: "bold" }}>Ανέβασμα
                        Αρχείου</span></p>
                        <p className="c5"><span className="c10">Για να
                        ανεβάσετε ένα
                        αρχείο, επιλέξτε
                        το κουμπί
                        “Αναζήτηση” ώστε να
                        το επιλέξετε και
                        έπειτα πατήστε το
                        κουμπί “Ανέβασμα
                        αρχείου”.
                        Ενδεικτικά
                        μηνύματα θα
                        εμφανιστούν στο
                        πάνω μέρος της
                        οθόνης σας για την
                        πρόοδο της
                        μεταφόρτωσης.</span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '209.00px', height: '42.00px' }}><img alt="" src={require('../../images/help_images/image19.png')} style={{ width: '209.00px', height: '42.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c5"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '223.00px', height: '44.00px' }}><img alt="" src={require('../../images/help_images/image28.png')} style={{ width: '223.00px', height: '44.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c5"><span className="c4">Επιτρεπόμενες
                        επεκτάσεις/τύποι
                        αρχείων: </span></p>
                        <ul className="c16 lst-kix_4gd2mqs82fv0-0 start">
                            <li className="c5 c7 c2"><span className="c4">.pdf</span></li>
                            <li className="c5 c7 c2"><span className="c4">.png</span></li>
                            <li className="c5 c7 c2"><span className="c4">.jpeg ή .jpg</span></li>
                            <li className="c5 c7 c2"><span className="c4">.zip</span></li>
                            <li className="c5 c7 c2"><span className="c4">.rar</span></li>
                            <li className="c5 c7 c2"><span className="c4">.7z</span></li>
                            <li className="c5 c7 c2"><span className="c4">.xlsx ή .xls</span></li>
                            <li className="c5 c7 c2"><span className="c4">.doc ή .docx</span></li>
                        </ul>
                        <p className="c5 c3"><span className="c14 c6" /></p>
                        <p className="c5"><span className="c14 c6" style={{ fontWeight: "bold" }}>Ενέργειες
                        Αρχείων</span></p>
                        <p className="c5"><span className="c4">Κάθε αρχείο
                        έχει:</span></p>
                        <ul className="c16 lst-kix_385mn28h3qa6-0 start">
                            <li className="c5 c7 c2"><span className="c10">Επιλογή
                        κατεβάσματος/download </span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '20.00px', height: '20.61px' }}><img alt="" src={require('../../images/help_images/image40.png')} style={{ width: '20.00px', height: '20.61px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span className="c4">&nbsp;</span></li>
                            <li className="c5 c7 c2"><span className="c10">Επιλογή
                            οριστικής
                        διαγραφής</span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '20.00px', height: '21.76px' }}><img alt="" src={require('../../images/help_images/image23.png')} style={{ width: '20.00px', height: '21.76px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span className="c4">.</span></li>
                        </ul>
                        <hr style={{ pageBreakBefore: 'always', display: 'none' }} />
                        <p className="c1 c3"><span className="c4" /></p>
                        <br />
                        <Title level={4} className="c17 c8" id="h.7x16iqp2sej6"><span className="c13 c6">Πως μπορώ
                        να μοιραστώ μία
                        διαδικασία με έναν
                        άλλο χρήστη;</span></Title>
                        <p className="c5"><span className="c4">Μπορείτε να
                        ορίσετε σε
                        ποιους χρήστες
                        (συνεργάτες) θα
                        εμφανίζεται μία
                        διαδικασία κατά την
                        έναρξη της ή κατά την
                        επεξεργασία των
                        στοιχείων της.</span></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '281.11px', height: '395.50px' }}><img alt="" src={require('../../images/help_images/image31.png')} style={{ width: '281.11px', height: '395.50px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span className="c10">&nbsp;</span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '179.67px', height: '394.61px' }}><img alt="" src={require('../../images/help_images/image7.png')} style={{ width: '179.67px', height: '394.61px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c5"><span className="c4">Στο πεδίο
                        “Κοινοποίηση με:”
                        εισάγετε ένα ή
                        περισσότερα &nbsp;e-mail
                        χρηστών με τους
                        οποίους θέλετε
                        να μοιραστείτε την
                        διαδικασία αυτή. (e-mail
                        με το οποίο είναι
                        εγγεγραμμένος στο
                        Schedio)</span></p>
                        <p className="c5 c3"><span className="c4" /></p>
                        <p className="c5"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '397.00px', height: '45.00px' }}><img alt="" src={require('../../images/help_images/image54.png')} style={{ width: '397.00px', height: '45.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c5"><span className="c15 c22">Αν το e-mail δεν
                        αντιστοιχεί σε
                        χρήστη του Schedio, θα
                        εμφανιστεί μήνυμα
                        λάθους.</span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <h4 className="c0" id="h.xspurpr6t1y5"><span className="c14 c6">Μοιραζόμενη
                        Διαδικασία ως
                        ιδιοκτήτης</span></h4>
                        <p className="c1"><span className="c4">Ιδιοκτήτης
                        μιας μοιραζόμενης
                        διαδικασίας είναι
                        ο χρήστης ο οποίος
                        πραγματοποίησε την
                        έναρξη της και
                        πρόσθεσε
                        συνεργάτες
                        (κοινοποίηση με) σε
                        αυτή. Ως
                        ιδιοκτήτης έχετε
                        πλήρη έλεγχο της
                        διαδικασίας και
                        των μελών της.</span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <h4 className="c0" id="h.ophh4btma98b"><span className="c14 c6">Μοιραζόμενη
                        Διαδικασία ως
                        συνεργάτης</span></h4>
                        <p className="c1"><span className="c4">Αν κάποιος
                        χρήστης έχει
                        προστεθεί από
                        κάποιον άλλον σε
                        μία διαδικασία
                        τότε είναι
                        συνεργάτης της
                        διαδικασίας
                        αυτής και έχει τα
                        εξής δικαιώματα:</span></p>
                        <ul className="c16 lst-kix_f74b2y82z035-0 start">
                            <li className="c1 c7 c2"><span className="c4">Προβολή όλων
                            των στοιχείων της
                        διαδικασίας</span></li>
                            <li className="c1 c7 c2"><span className="c4">Διαχείριση
                            Βημάτων
                        (Επόμενο-Προηγούμενο-Προθεσμίες)</span>
                            </li>
                            <li className="c1 c2 c7"><span className="c4">Πλήρη
                            προβολή
                        χρονοδιαγράμματος</span>
                            </li>
                            <li className="c1 c7 c2"><span className="c4">Ανέβασμα
                        αρχείων</span></li>
                        </ul>
                        <p className="c1"><span className="c4">Όμως δεν έχει
                        τα εξής
                        δικαιώματα:</span></p>
                        <ul className="c16 lst-kix_k406ri4geqas-0 start">
                            <li className="c1 c7 c2"><span className="c4">Επεξεργασία
                            στοιχείων
                        διαδικασίας</span></li>
                            <li className="c1 c7 c2"><span className="c4">Αρχειοθέτηση
                        διαδικασίας</span></li>
                            <li className="c1 c7 c2"><span className="c4">Προσθήκη
                            συνεργατών
                        (κοινοποίηση με)</span></li>
                        </ul>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '402.00px', height: '97.00px' }}><img alt="" src={require('../../images/help_images/image44.png')} style={{ width: '402.00px', height: '97.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c9"><span className="c15">Στην λίστα
                        διαδικασιών, μία
                        μοιραζόμενη
                        διαδικασία
                        ξεχωρίζει διότι
                        στο δεξί μέρος του
                        πλαισίου υπάρχει
                        το σύμβολο της
                        κοινής χρήσης </span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '23.00px', height: '20.00px' }}><img alt="" src={require('../../images/help_images/image46.png')} style={{ width: '23.00px', height: '20.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span className="c15 c22">.</span></p>
                        <p className="c9 c3"><span className="c15 c22" /></p>
                        <p className="c9 c3"><span className="c15 c22" /></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '437.00px', height: '122.00px' }}><img alt="" src={require('../../images/help_images/image65.png')} style={{ width: '437.00px', height: '122.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c9"><span className="c15 c22">Σε μία
                        μοιραζόμενη
                        διαδικασία
                        εμφανίζεται το
                        επίθετο και το
                        εικονίδιο του
                        ιδιοκτήτη.</span></p>
                        <p className="c9 c3"><span className="c15 c22" /></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '372.17px', height: '195.92px' }}><img alt="" src={require('../../images/help_images/image56.png')} style={{ width: '372.17px', height: '195.92px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c9"><span className="c15 c22">Σε μία
                        μοιραζόμενη
                        διαδικασία όλοι
                        οι χρήστες
                        ενημερώνονται με e-mail
                        για τις κινήσεις
                        των συνεργατών
                        τους στην
                        διαδικασία αυτή.</span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <hr style={{ pageBreakBefore: 'always', display: 'none' }} />
                        <p className="c1 c3"><span className="c4" /></p>

                    </TabPane>

                    <TabPane tab="Έργο" key="3">

                        <Title level={4} className="c17 c8" id="h.5t7ioy8ilfd8"><span className="c6 c13">Πως μπορώ
                        να δημιουργήσω ένα
                        νέο έργο;</span></Title>
                        <p className="c1"><span className="c4">Με την
                        δημιουργία ενός
                        νέου λογαριασμού
                        δεν υπάρχει κάποιο
                        έργο ώστε να
                        προσθέσετε σε
                        αυτό διαδικασίες.
                        Αυτό όμως δεν
                        σημαίνει ότι δεν
                        μπορείτε να
                        προσθέσετε
                        διαδικασίες
                        γενικά. Τα έργα
                        βοηθούν στην
                        ομαδοποίηση και
                        κατηγοριοποίηση
                        των διαδικασιών (ένα
                        σύνολο
                        διαδικασιών που
                        συνδέεται με
                        κάποιο τρόπο), αλλά
                        δεν είναι
                        απαραίτητο μία
                        διαδικασία να
                        ανήκει σε κάποιο
                        έργο.</span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c1"><span className="c4">Για να
                        δημιουργήσετε ένα
                        έργο:</span></p>
                        <ol className="c16 lst-kix_e7f411o30o5u-0 start" start={1}>
                            <li className="c1 c7 c2"><span className="c10">Πατήστε το
                            κουμπί “Προσθήκη”
                            στο πάνω αριστερά
                        μέρος του dashboard.</span>
                                <br />
                                <span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '399.89px', height: '265.50px' }}><img alt="" src={require('../../images/help_images/image13.png')} style={{ width: '399.89px', height: '265.50px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></li>
                            <li className="c1 c7 c2"><span className="c4">Επιλέξτε το
                        κουμπί “Έργο”.</span></li>
                        </ol>
                        <p className="c1"><span className="c10">`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '442.64px', height: '242.50px' }}><img alt="" src={require('../../images/help_images/image27.png')} style={{ width: '442.64px', height: '242.50px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c12 c3"><span className="c4" /></p>
                        <ol className="c16 lst-kix_e7f411o30o5u-0" start={3}>
                            <li className="c1 c7 c2"><span className="c4">Συμπληρώστε
                            τα απαραίτητα
                            στοιχεία του
                            έργου</span>
                                <ul className="c16 lst-kix_e7f411o30o5u-1 start" start={1}>
                                    <li className="c1 c12 c7"><span className="c4">Τίτλος
                                    έργου
                                        (*απαραίτητο)</span></li>
                                    <li className="c1 c12 c7"><span className="c4">Τον κωδικό
                                    ΕΛΚΕ του έργου
                                        (όχι απαραίτητο)</span></li>
                                    <li className="c1 c12 c7"><span className="c4">Την περιγραφή
                                        του έργου</span></li>
                                </ul>
                            </li>
                        </ol>
                        <p className="c1 c12"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '210.67px', height: '393.50px' }}><img alt="" src={require('../../images/help_images/image58.png')} style={{ width: '210.67px', height: '393.50px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <ol className="c16 lst-kix_e7f411o30o5u-0" start={4}>
                            <li className="c1 c7 c2"><span className="c4">Τέλος
                            πατήστε το κουμπί
                            της
                        “Δημιουργία”.</span></li>
                        </ol>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c1 c3"><span className="c4" /></p><br />
                        <Title level={4} className="c8 c17" id="h.hr8qo16qfxyk"><span className="c13 c6">Πως μπορώ
                        να προσθέσω
                        διαδικασίες σε
                        ένα νέο έργο;</span></Title>
                        <p className="c1"><span className="c11">Μπορείτε να
                        προσθέσετε μία
                        διαδικασία σε ένα
                        έργο με τους εξής 2
                        τρόπους :</span></p>
                        <ol className="c16 lst-kix_w3op76m7lvzm-0 start" start={1}>
                            <li className="c1 c7 c2"><span className="c11">Κατά την έναρξη
                            της διαδικασίας
                            μπορείτε να
                            επιλέξετε σε
                            ποιο έργο θα
                        ανήκει.</span></li>
                        </ol>
                        <p className="c1 c2"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '155.06px', height: '375.50px' }}><img alt="" src={require('../../images/help_images/image6.png')} style={{ width: '155.06px', height: '375.50px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <ol className="c16 lst-kix_w3op76m7lvzm-0" start={2}>
                            <li className="c1 c7 c2"><span className="c11">Σε μία
                            υπάρχουσα
                            διαδικασία
                            μπορείτε να
                            επιλέξετε σε
                            ποιο έργο θα
                            ανήκει,
                            αλλάζοντας τα
                            στοιχεία της
                            διαδικασίας από
                            την ενότητα
                            “Επεξεργασία
                        Διαδικασίας”.</span></li>
                        </ol>
                        <p className="c5 c2"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '446.00px', height: '89.00px' }}><img alt="" src={require('../../images/help_images/image37.png')} style={{ width: '602.00px', height: '89.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '242.50px', height: '339.50px' }}><img alt="" src={require('../../images/help_images/image30.png')} style={{ width: '242.50px', height: '339.50px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c5 c3 c2"><span className="c4" /></p><br />
                        <Title level={4} className="c27 c8" id="h.xfzp11bnnlme"><span className="c13 c6">Πως μπορώ
                        να δω ποιες
                        διαδικασίες
                        ανήκουν σε ένα
                        έργο μου;</span></Title>
                        <p className="c1"><span className="c4">Στην λίστα
                        διαδικασιών
                        εμφανίζονται όλες
                        οι διαδικασίες
                        ενός χρήστη, είτε
                        ανήκουν σε κάποιο
                        έργο, είτε όχι.
                        Επιλέγοντας
                        κάποιο έργο στην
                        ενότητα “Επιλογή
                        Έργου” στο
                        αριστερό μέρος του
                        dashboard, η λίστα αυτή
                        φιλτράρετε και
                        προβάλλει μόνο τις
                        διαδικασίες του
                        επιλεγμένου
                        έργου.</span></p>
                        <p className="c1"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '602.00px', height: '312.00px' }}><img alt="" src={require('../../images/help_images/image14.png')} style={{ width: '602.00px', height: '312.00px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '363.57px', height: '352.50px' }}><img alt="" src={require('../../images/help_images/image59.png')} style={{ width: '363.57px', height: '352.50px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c9"><span className="c15">Κανένα
                        επιλεγμένο έργο
                        (Επιλογή ”Όλα”),
                        στην λίστα
                        εμφανίζονται όλες
                        οι διαδικασίες
                        του χρήστη.</span><span className="c11">&nbsp;</span></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '380.46px', height: '368.88px' }}><img alt="" src={require('../../images/help_images/image32.png')} style={{ width: '380.46px', height: '368.88px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c9"><span className="c15 c22">Επιλεγμένο
                        έργο με ονομασία
                        “Πρώτο Έργο”), στην
                        λίστα εμφανίζονται
                        όλες οι
                        διαδικασίες που
                        ανήκουν στο έργο
                        αυτό.</span></p>
                        <p className="c9 c3"><span className="c15 c22" /></p><br />
                        <Title level={4} className="c27 c8" id="h.p39u7isw59jt"><span className="c13 c6">Πως μπορώ
                        να δω σε ποιο έργο
                        ανήκει μία
                        διαδικασία;</span></Title>
                        <p className="c5"><span className="c4">Αφού
                        επιλέξετε μία
                        διαδικασία και
                        φορτώσουν οι
                        λεπτομέρειες της
                        διαδικασίας στο
                        δεξί μέρος του dashboard,
                        θα παρατηρήσετε
                        πάνω στα στοιχεία
                        της διαδικασίας
                        ότι υπάρχει η
                        επιλογή “Προβολή
                        Έργου”. Πατώντας το
                        κουμπί αυτό θα
                        ανοίξει ένα
                        παράθυρο με τα
                        στοιχεία του
                        έργου στο οποίο
                        ανήκει η επιλεγμένη
                        διαδικασία.</span></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '324.67px', height: '325.53px' }}><img alt="" src={require('../../images/help_images/image60.png')} style={{ width: '324.67px', height: '325.53px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '375.67px', height: '217.97px' }}><img alt="" src={require('../../images/help_images/image4.png')} style={{ width: '375.67px', height: '217.97px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c1 c3"><span className="c4" /></p><br />
                        <Title level={4} className="c8 c27" id="h.9o8m52u9vjv9"><span className="c13 c6">Μπορώ να
                        αλλάξω τα στοιχεία
                        του έργου μου;</span></Title>
                        <p className="c1"><span className="c4">Εφόσον
                        επιλέξετε κάποιο
                        έργο από την
                        επιλογή έργου, το
                        κουμπί
                        “Επεξεργασία
                        Έργου”
                        ενεργοποιείται
                        από κάτω. Πατώντας το
                        θα ανοίξει ένα
                        παράθυρο που θα
                        σας επιτρέπει την
                        επεξεργασία των
                        στοιχείων του.</span></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '319.17px', height: '404.43px' }}><img alt="" src={require('../../images/help_images/image43.png')} style={{ width: '319.17px', height: '404.43px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c9"><span className="c15 c22">Το παράθυρο
                        αυτό είναι όμοιο
                        με την ενότητα
                        δημιουργίας
                        Έργου.</span></p>
                        <p className="c5 c3"><span className="c4" /></p>
                        <p className="c5 c3"><span className="c4" /></p>
                        <hr style={{ pageBreakBefore: 'always', display: 'none' }} />
                        <h2 className="c5 c8 c32" id="h.y4mzsnxavef8"><span className="c20" /></h2>

                    </TabPane>

                    <TabPane tab="Προφίλ" key="4">

                        <Title level={4} className="c17 c8" id="h.y5g73s4xurcs"><span className="c13 c6">Πως μπορώ
                        να αλλάξω τα
                        στοιχεία του
                        προφίλ μου;</span></Title>
                        <p className="c1"><span className="c4">Κάθε χρήστη
                        του Schedio μπορεί να
                        προσθέσει τα
                        προσωπικά του
                        στοιχεία πατώντας
                        την επιλογή
                        “Προφίλ” από το
                        πλάγιο μενού. Η
                        επιλογή αυτή θα
                        ανοίξει ένα πλάγιο
                        παράθυρο Το οποίο
                        προβάλλει και
                        επιτρέπει την
                        επεξεργασία των
                        παρακάτω στοιχείων
                        του:</span></p>
                        <ul className="c16 lst-kix_radkhd1mi0n9-0 start">
                            <li className="c1 c7 c2"><span className="c4">Όνομα</span></li>
                            <li className="c1 c7 c2"><span className="c4">Επίθετο</span></li>
                            <li className="c1 c7 c2"><span className="c4">E-mail</span></li>
                            <li className="c1 c7 c2"><span className="c4">Οργανισμός
                        (Πανεπιστήμιο)</span></li>
                            <li className="c1 c7 c2"><span className="c4">Φωτογραφία
                        Προφίλ</span></li>
                        </ul>
                        <p className="c1 c3"><span className="c4" /></p>
                        <p className="c1"><span className="c4">Πατώντας το
                        κουμπί ενημέρωση, θα
                        ενημερωθούν τα
                        στοιχεία του
                        χρήστη με βάση τις
                        αλλαγές που
                        έγιναν.</span></p>
                        <p className="c9"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '304.79px', height: '525.50px' }}><img alt="" src={require('../../images/help_images/image10.png')} style={{ width: '304.79px', height: '531.62px', marginLeft: '0.00px', marginTop: '-6.12px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>

                    </TabPane>

                    <TabPane tag="Έντυπα" key="5">

                        <Title level={4} className="c17 c8" id="h.3oz14sprmqum"><span className="c13 c6">Που μπορώ
                        να βρω τα
                        δικαιολογητικά
                        που χρειάζομαι;</span></Title>
                        <p className="c1"><span className="c4">Το Schedio παρέχει
                        κάποια
                        δικαιολογητικά
                        έντυπα ερευνητικών
                        διαδικασιών για να
                        βοηθήσει τον
                        χρήστη, δίνοντας
                        του γρήγορη
                        πρόσβαση σε αυτά.
                        Αυτά μπορούν να
                        βρεθούν
                        επιλέγοντας τα
                        “Έντυπα” από το
                        πλάγιο μενού και
                        είναι διαθέσιμα
                        για κατέβασμα.</span></p>
                        <p className="c1"><span className="c4">Είδη
                        δικαιολογητικών:</span>
                        </p>
                        <ul className="c16 lst-kix_t9lt6rq6k9k2-0 start">
                            <li className="c1 c7 c2"><span className="c4">Διοικητικά</span>
                            </li>
                            <li className="c1 c7 c2"><span className="c4">Οικονομικά</span>
                            </li>
                            <li className="c1 c7 c2"><span className="c4">Αιτήσεις</span></li>
                        </ul>
                        <p className="c1 c3 c2"><span className="c4" /></p>
                        <p className="c1"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '602.00px', height: '570.67px' }}><img alt="" src={require('../../images/help_images/image52.png')} style={{ width: '602.00px', height: '575.49px', marginLeft: '0.00px', marginTop: '-4.83px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} title /></span></p>
                        <p className="c5 c3"><span className="c22 c26" /></p>

                    </TabPane>

                </Tabs>
            </div>
        )
    }
}

export default HelpPage
