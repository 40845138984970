import React from 'react';
import { Route, Switch } from 'react-router-dom'
import LoginForm from './components/Misc/LoginForm'
import RegisterForm from './components/Misc/RegisterForm'
import Dashboard from './components//Dashboard/Dashboard'
import HomePage from './components/Misc/HomePage'
import CreateSection from './components/Create/CreateSection'
import CreatePrProcedure from './components/Create/CreatePrProcedure'
import CreateProject from './components/Create/CreateProject'
import ErrorPage from './components/Misc/ErrorPage'
import { HelpPage } from './components/Misc/HelpPage';

const BaseRouter = (props) => (
    <Switch>
        <Route exact path='/' name="home" render={(routeProps) => <HomePage {...routeProps} {...props}/>}></Route>
        <Route path="/login" render={(routeProps) => <LoginForm {...routeProps} {...props}/>}></Route>
        <Route path="/register" render={(routeProps) => <RegisterForm {...routeProps} {...props}/>}></Route>
        <Route path="/dashboard" render={(routeProps) => <Dashboard {...routeProps} {...props}/>}></Route>
        <Route path="/create" render={(routeProps) => <CreateSection {...routeProps} {...props}/>}></Route>
        <Route path="/add_procedure" render={(routeProps) => <CreatePrProcedure {...routeProps} {...props}/>}></Route>
        <Route path="/create_project" render={(routeProps) => <CreateProject {...routeProps} {...props}/>}></Route>
        <Route path="/help_page" render={(routeProps) => <HelpPage {...routeProps} {...props}/>}></Route>
        <Route component={ErrorPage}></Route>
    </Switch>
);

export default BaseRouter;