import {message} from 'antd';

export function errorHandler(err){

    if(err.response !== undefined){
        if(err.response.data !== undefined){
            let data = err.response.data

            if('detail' in data)
                message.warning(data.detail)
            else if ('error' in data)
                message.warning(data.error)
            else {

                var allPropertyNames = Object.keys(data);
                for (var j=0; j<allPropertyNames.length; j++) {
                    var name = allPropertyNames[j];
                    var value = data[name];
                    
                    message.warning(value)
                    
                }
            }
            
        }
        else 
            message.error("Άγνωστο σφάλμα.")
    }
    else message.error("Άγνωστο σφάλμα.")
        
}