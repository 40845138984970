import { Tag, Input, Tooltip, Icon } from 'antd';
import React from 'react';

export default class EditableTagGroup extends React.Component {
    state = {
        inputVisible: false,
        inputValue: '',
    };

    handleClose = removedTag => {
        this.props.removeTag(removedTag)
    };

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());
    };

    handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = () => {
        if (this.state.inputValue.length > 1)
            this.props.addTag(this.state.inputValue);

        this.setState({
            inputVisible: false,
            inputValue: '',
        });
    };

    saveInputRef = input => (this.input = input);

    render() {
        const { inputVisible, inputValue } = this.state;

        return (
            <div style={{maxWidth: "30em"}}>
                {this.props.tags && this.props.tags.map((tag, index) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                        <Tag key={tag} closable={this.props.owned || this.props.owned === undefined} onClose={() => this.handleClose(tag)}>
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                    );
                    return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                        {tagElem}
                        </Tooltip>
                    ) : (
                        tagElem
                    );
                })}

                {(this.props.owned || this.props.owned === undefined) &&
                    <div>
                        {inputVisible ? 
                            <Input
                                ref={this.saveInputRef}
                                type="text"
                                size="small"
                                style={{ width: 78 }}
                                value={inputValue}
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputConfirm}
                                onPressEnter={this.handleInputConfirm}
                            />
                        :
                            <Tag style={{border: "1px dashed grey"}} className="site-tag-plus" onClick={this.showInput}>
                                <Icon type="plus"/> E-mail επιπλέον χρήστη
                            </Tag>
                        }
                </div>}
            </div>
        );
    }
}
