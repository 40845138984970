import React from 'react';
import {
    Form, Icon, Input, Button
} from 'antd';
import logo from '../../images/schedio_short_logo.png';
import * as actions from '../../store/actions/auth';
import { connect} from 'react-redux';
import { ntwrk_err, succ_register } from '../_shared/messages';
import '../_styles/LoginForm.scss';

class RegisterForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password1: '',
            password2: '',
            email: '',
            loading: false
        };


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    state = {
        confirmDirty: false,
        autoCompleteResult: [],
    };

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({loading : true});

        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            this.props.onAuth(this.state.username,this.state.email, this.state.password1, this.state.password2).then(() => {
                this.setState({loading : false}); // Added on top to prevent componentWillUnmount errors

                if(this.props.error === null || this.props.error === undefined){
                    succ_register();
                    this.props.history.push('/', {
                        selection: "home"
                    });
                }else{
                    if(this.props.error.response === undefined){
                        this.props.form.setFields({
                            "username": {
                                value: "",
                                errors: [new Error("")],
                            },
                            "email":{
                                value: "",
                                errors: [new Error("")],
                            },
                            "password": {
                                value: "",
                                errors: [new Error("")],
                            },
                            "confirm": {
                                value: "",
                                errors: [new Error("Σφάλμα δικτύου. Προσπαθήστε ξανά αργότερα.")],
                            }
                        });

                        ntwrk_err();
                    }
                    else{

                        this.props.form.setFields({
                            "username": {
                                value: this.props.form.getFieldValue("username"),
                                errors: (this.props.error.response.data.username ? [new Error(this.props.error.response.data.username)] : null),
                            },
                            "email":{
                                value: this.props.form.getFieldValue("email"),
                                errors: (this.props.error.response.data.email ? [new Error(this.props.error.response.data.email)] : null),
                            },
                            "password": {
                                value: "",
                                errors: [new Error(this.props.error.response.data.password1)],
                            },
                            "confirm": {
                                value: "",
                                errors: [new Error('')],
                            }
                        }); 
                }
                }
            });
        }else
            this.setState({loading : false});
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }


    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password'))
          callback('Οι κωδικοί δεν είναι ίδιοι!');
        else
          callback();
    }
    
    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty)
          form.validateFields(['confirm'], { force: true });
        callback();
    }

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <div id="flex-container">
                <img id="logo" src={logo} href="/"  alt="logo"></img>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item label={("Όνομα χρήστη")}>
                        {getFieldDecorator('username', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Παρακαλώ εισάγετε το όνομα χρήστη!',
                                    whitespace: true 
                                }],
                        })(
                            <Input name="username" onChange={this.handleChange} prefix={<Icon type="user"/>}/>
                        )}
                    </Form.Item>

                    <Form.Item label="E-mail">
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'Μη-έγκυρο e-mail!',
                                }, 
                                {
                                    required: true, message: 'Παρακαλώ εισάγετε το e-mail σας!',
                                }]
                        })(
                            <Input name="email"  onChange={this.handleChange} prefix={<Icon type="mail"/>}/>
                        )}
                    </Form.Item>

                    <Form.Item label="Συνθηματικό">
                        {getFieldDecorator('password', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Παρακαλώ εισάγετε το συνθηματικό σας!',
                                },
                                {
                                    min:8,
                                    message: "Το συνθηματικό πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες!"
                                },
                                {
                                    validator: this.validateToNextPassword,
                                }]
                        })(
                            <Input name="password1" type="password" onChange={this.handleChange} prefix={<Icon type="lock"/>}/>
                        )}
                    </Form.Item>

                    <Form.Item label="Επιβεβαίωση συνθηματικού">
                        {getFieldDecorator('confirm', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Πρέπει να επιβεβαιώσετε το συνθηματικό!',
                                },
                                {
                                    validator: this.compareToFirstPassword,
                                }]
                        })(
                            <Input name="password2" type="password" onBlur={this.handleConfirmBlur} onChange={this.handleChange} prefix={<Icon type="lock"/>}/>
                        )}
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="register-form-button">Εγγραφή</Button>
                    </Form.Item>
                </Form>

                {this.state.loading &&
                    <div><Icon id="loading-spinner" type="loading" spin /></div>
                }
            </div>
        );
    }
}

const WrappedRegistration = Form.create()(RegisterForm)

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username,email,password1,password2) => dispatch(actions.authSignup(username,email,password1,password2))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistration);
