import React, { Component } from 'react'
import Animate from 'rc-animate';
import '../_styles/CreateSection.scss'
import {Typography, Button} from 'antd';
import procedure_icon from '../../images/procedure_icon_medium.png'
import project_icon from '../../images/project_icon_medium.png'
import { NavLink } from 'react-router-dom';

const { Title } = Typography;


export default class CreateSection extends Component {

    constructor() {
            super(...arguments);
            this.state = {
            selection : null
            };

    }

    render() {
        return (
            <div>
                
                <Animate
                    transitionName="fade"
                    transitionAppear
                >
                    <div id="flex-container">

                        <Title level={3}>Επιλέξτε τι θέλετε να κάνετε</Title>

                        <div className="master-flex-row-create-section">

                            {/* Add Procedure Option */}
                            <div className="flex-column-margin-top">
                                <Title level={4}>Να ξεκινήσετε μια νέα διαδικασία.</Title>
                                <div className="space"></div>
                                <img src={procedure_icon} alt="procedure_icon"></img>
                                <div className="space"></div>
                                <NavLink to="/add_procedure">
                                    <Button type="primary" size="large" onClick={this.onClickProcedure} >Διαδικασία</Button>
                                </NavLink>

                            </div>

                            {/* Create Project Option */}
                            <div className="flex-column-margin-top">
                                <Title level={4}>Να δημιουργήσετε έργο.</Title>
                                <div className="space"></div>
                                <img src={project_icon} alt="project_icon"></img>
                                <div className="space"></div>
                                <NavLink to="/create_project">
                                <Button type="danger" size="large" onClick={this.onClickProject}>Έργο</Button>
                                </NavLink>

                            </div>

                        </div>

                    </div>
                </Animate>

            </div>
        )
    }
}
