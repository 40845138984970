import React, { Component } from 'react'
import '../_styles/Dashboard.scss'
import {Button, Timeline, Modal, Skeleton, Icon, Tooltip, Row} from 'antd';
import API from '../../utils/API';
import {errorHandler} from '../../utils/errorHandler';
import moment from 'moment'
import 'moment/locale/el.js'

export default class TimelineWindow extends Component {

    constructor(props){
        super(props);

        this.refreshTimeline = this.refreshTimeline.bind(this);
        this.timeline = this.timeline.bind(this);
    }

    state = {
        timelineData : undefined,
        loading : false,
        disabled : true,
        modalVisible :false
    }

    // Refresh timeline data when an update occurs
    componentDidUpdate(prevProps){
        if(prevProps.selected_procedure !== this.props.selected_procedure){
            this.refreshTimeline();
        }

    }

    // Get updated history data on given procedure
    refreshTimeline(){
        if(this.props.selected_procedure !== undefined){
            API.get('ctrl/time_diag/' + this.props.selected_procedure.distinct_procedure, {
                headers : {"Authorization": "Token " + localStorage.getItem('token')}
            })
            .then(res => {
                this.setState({
                    timelineData : res.data,
                    disabled : false
                })
            })
            .catch(err => errorHandler(err))
        }
        // If theres no selected procedure, disable "View More Info" button and make timeline data undefined
        else {
            this.setState({
                timelineData : undefined,
                disabled : true
            });
        }
    }

    // Chooses dot color for each substep
    chooseSubstepColor(completed, current){
        if(current){
            if (completed){
                return "green"
            }
            else return "blue"
        }
        else return "grey"
    }

    // Setting up substeps for each main step in Timeline
    // Boolean variable "current": 
    // - Is true when a step is completed or current 
    // - Is false when a step is uncompleted
    setupSubsteps(substeps, current){
        if(substeps !== null && substeps!== undefined){
            let subs = substeps.map((item,i) => 
                <Timeline.Item 
                    key={i} 
                    dot={(!item.completed && item.meta.deadline !== null) && 
                        <Tooltip title={"Προθεσμία: " + moment(item.meta.deadline).endOf('day').fromNow()}>
                            <Icon style={{fontSize : "14px" }} type="clock-circle-o" />
                        </Tooltip>} 
                    color={this.chooseSubstepColor(substeps[i].completed, current)}
                    >
                    <div style={{fontSize: "11px"}}>
                        {item.title}
                        {item.completed &&
                            <Tooltip title={moment(item.meta.end_date).format('LLL')}>
                                <Icon style={{marginLeft : "5px"}} type="carry-out" />
                            </Tooltip>
                        }
                    </div>
                </Timeline.Item>
            )
        return [subs];
        }
        // If there's no substeps return an empty array
        else return [];
    }

    // Function for modal's timeline, includes date/time info for each step
    timeline(detailed){
        if(this.state.timelineData !== undefined){

            // Completed steps (Marked with green)
            // If there are substeps for a main step, display them as well
            let completed = this.state.timelineData["completed"].map((item,i) =>
                <div>
                    <div>
                        <Timeline.Item key={"sub" + i} color="green">
                                {item.title}
                                {detailed &&
                                    <div style={{fontSize: "11px"}}>{moment(item.meta.end_date).locale('el').format('LLL')}</div>
                                }
                        </Timeline.Item>
                        {detailed &&
                            <div>
                                {this.setupSubsteps(this.state.timelineData.completed[i].substeps, true)}
                            </div>
                        }
                    </div>
                </div>
            );

            // Current steps (Marked with blue)
            // If there are substeps for current step, display them as well
            // If there's no current step (procedure is finished), display green check icon.
            let current = (
                <div style={{textAlign: "center"}}>
                    {this.state.timelineData.current_main !== null ?

                        <div>
                            <Timeline.Item 
                                dot={this.state.timelineData.current_main.meta.deadline !== null && 
                                    <Tooltip title={"Προθεσμία: " + moment(this.state.timelineData.current_main.meta.deadline).endOf('day').fromNow()}>
                                        <Icon type="clock-circle-o" />
                                    </Tooltip>} 
                                key={0} 
                                color="blue">
                                {this.state.timelineData.current_main.title}

                            </Timeline.Item>

                            {detailed &&
                                <div>
                                    {this.setupSubsteps(this.state.timelineData.current_main.substeps, true)}
                                </div>
                            }
                        </div>

                    :

                    <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{fontSize: "20px"}}/>

                    }
                </div>
                    
            );

            // Uncompleted steps (Marked with grey)
            // If there are substeps for an uncompleted step, display them as well
            let uncompleted = this.state.timelineData["uncompleted"].map((item,i) =>
                <Timeline.Item key={i} color="grey">
                    <div style={{opacity: "0.5"}}>
                        {item.title}
                    </div>
                </Timeline.Item>
            );

            return [completed, current, uncompleted]
        }
        else{
            return <Skeleton></Skeleton>;
        }
    }

    // Display's detailed timeline modal
    showModal = () => {
        this.setState({
            modalVisible: true,
        });
      };

    // Closes modal window
    handleOk = e => {
        this.setState({
            modalVisible: false,
        });
      };
    

    render() {
        return (
                <Row className="timeline-container">
                    <div className="header">Χρονοδιάγραμμα</div>

                    <hr className="hr2" />

                    {/* Basic Timeline */}
                    <div className="scrollable-timeline">
                        <Timeline mode="alternate" style={{marginTop:"20px" , marginLeft:"10px", marginRight:"10px"}}>
                            {this.timeline(false)}
                        </Timeline>
                    </div>

                    <hr className="hr2"></hr>
                    {/* Detailed Timeline */}
                    <div style={{ textAlign : "center"}}>
                        <Button type="primary" disabled={this.state.disabled} onClick={this.showModal} style={{margin:"15px"}}>Προβολή Λεπτομερειών</Button>
                    </div>

                    <Modal
                        footer={[<Button key="submit" type="primary" onClick={this.handleOk}>Κλείσιμο</Button>]}
                        title="Χρονοδιάγραμμα"
                        visible={this.state.modalVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleOk}
                        okText={"Κλείσιμο"}
                        cancelText={""}
                        >
                        {<Timeline className="scrollable-timeline" mode="alternate" style={{marginTop:"20px" , marginLeft:"10px", marginRight:"10px"}}>
                            {this.timeline(true)}
                        </Timeline>}
                    </Modal>
                </Row>
        )
    }
}