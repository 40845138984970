import React, { Component } from 'react'
import '../_styles/Dashboard.scss'
import {Button, List, Input, Progress, Icon, Row, Col} from 'antd';
import { Tooltip } from 'antd';
import { Avatar } from 'antd';


export default class ProcedureList extends Component {

    constructor(props){
        super(props);

        this.onSearch = this.onSearch.bind(this);
        this.handleSearchTextChangehandleChange = this.handleSearchTextChange.bind(this);
        this.getProcedures = this.getProcedures.bind(this);
        this.filterProcedures = this.filterProcedures.bind(this);
    }

    state = {
        loading : false,
        procedures : undefined,
        filtered_procedures : undefined,
        search_text : "Αναζήτηση",
        search_filter : false,
        project_filter : false,
        selected_procedure : undefined
    }

    // Refreshes data on list, everytime dashboard updates them.
    componentDidUpdate(prevProps){
        if(prevProps !== this.props){

            if(this.props.procedures !== undefined){

                if(this.props.selected_project === undefined){
                    this.setState({project_filter : false});
                }
                else{
                    this.setState({project_filter : true});
                }

                let procedures = this.props.procedures
                
                if(!this.props.archived){
                    procedures = procedures.filter((procedure) => {
                        return procedure.visible === true
                    })
                }
                
                this.setState({procedures : procedures,
                    selected_procedure : this.props.selected_procedure
                },
                () => {
                    this.filterProcedures();
                });
            }
        }
    }

    filterProcedures(){
        let procedures;

        procedures = this.state.procedures;

        if(this.state.search_filter){
            procedures = procedures.filter((title) => {
                    return title.title.includes(this.state.search_text) || title.distinct_procedure.includes(this.state.search_text);
                })
        }
        if(this.state.project_filter){
            procedures = procedures.filter((procedure) => {
                return procedure.project === this.props.selected_project;
            })
        }

        this.setState({filtered_procedures : procedures});
    }

    // Triggers when search button is pressed
    onSearch(){
        // Turn empty search to its initial state.
        if(this.state.search_text === ""){
            this.setState({search_filter : false}, () => {
                this.filterProcedures();
            });
            this.setState({search_text : "Αναζήτηση"});
        }
        // Filter procedure list
        if(this.state.search_text !== "" && this.state.search_text !== "Αναζήτηση"){
            this.setState({search_filter : true}, () => {
                this.filterProcedures();
            });
        }
    }

    // Returns user's procedures filtered [search], unfiltered or none [empty array].
    getProcedures(){
        if(this.state.procedures !== undefined){
            // If there's a specific search going on...
            if(this.state.search_filter || this.state.project_filter){
                // If there's no procedure matching search attributes return an empty array.
                if(this.state.filtered_procedures.length === 0 || this.state.filtered_procedures.length === undefined){
                    return [];
                }
                // Return the filtered list of procedures that match the search context.
                else return this.state.filtered_procedures;
            }
            else {
                // Return the unfiltered list of procedures
                return this.state.procedures;
            }
        }
        // We return an empty array in case theres no Procedures created for this particular user.
        else return [];
    }


    // Change search text state when user types.
    handleSearchTextChange(evt){
        this.setState({ search_text : evt.target.value });
    }

    // Highlight procedure if its selected
    isSelected(item){
        if(this.state.selected_procedure !== undefined){
            if(item.id === this.state.selected_procedure.id){
                return {
                    backgroundColor: '#b9e1ff', 
                    borderRadius: '7px',
                    padding: '5px'
                }
            }
            else return {};
        }
    }

    render() {
        return (
            <div>
                <Row type="flex" justify={"space-around"}>
                    {/* Search Input */}
                    <Col span={20}>
                        <Input allowClear placeholder={this.state.search_text} onPressEnter={this.onSearch} onChange={evt => this.handleSearchTextChange(evt)}/>
                    </Col>
                    <Col span={2}>
                        <Button type="primary" icon="search" onClick={this.onSearch}></Button>
                    </Col>
                </Row>
                <div className="scroll-list" style={{ marginTop : "12px" }}>
                    {/* Procedure List */}
                    <List
                        itemLayout="horizontal"
                        dataSource={this.getProcedures()}
                        renderItem={item => (
                            <List.Item>
                                {/* Surround item with an "a" to make it clickable */}
                                <a className="flex-row unselectable-text movin" style={this.isSelected(item)} onClick={() => this.props.handleProcedureSelection(item)}>
                                <div className="vertical-line" style={{opacity : item.progress / 100}}></div>
                                
                                <List.Item.Meta
                                    title={item.title}
                                    description={
                                        <div>
                                            <div className="flex-row" style={{justifyContent: "space-between", maxWidth: "430px"}}>
                                                <div>
                                                    {item.distinct_procedure}
                                                    {item.owner && 
                                                        <Tooltip
                                                            title={item.owner.last_name !== "" ? "Ιδιοκτήτης: " + item.owner.last_name : "Ιδιοκτήτης: " + item.owner.email}
                                                        >
                                                            {item.owner.profile.avatar === null ?
                                                                <Avatar
                                                                    size={32}
                                                                    icon="user"
                                                                    style={{marginLeft: ".7em"}}
                                                                />
                                                            : 
                                                                <Avatar
                                                                    size={32}
                                                                    src={item.owner.profile.avatar}
                                                                    style={{marginLeft: ".7em"}}
                                                                />
                                                            }
                                                        </Tooltip>
                                                    }
                                                </div>
                                                
                                                {!item.owned &&
                                                    <div style={{fontSize:'1.2em', color:'#1890ff', marginRight:'.7em'}}>
                                                        <Tooltip title="Αυτή η διαδικασία έχει μοιραστεί με εσάς">
                                                            <Icon type="share-alt"/>
                                                        </Tooltip>
                                                    </div>
                                                }


                                                {!item.visible &&
                                                    <div style={{fontSize:'1.2em', marginRight:'.7em'}}>
                                                        <Tooltip title="Αυτή η διαδικασία έχει αρχειοθετηθεί">
                                                            <Icon type="file-excel"/>
                                                        </Tooltip>
                                                    </div>
                                                }
                                            </div>

                                            <Progress percent={item.progress}></Progress>
                                        </div>
                                    }/>
                                </a>
                            </List.Item>
                        )}
                    />
                </div>

                {this.state.loading && 
                    <div style={{display: "flex", justifyContent: "center"}}><Icon id="loading-spinner" type="loading" spin /></div>
                }
            </div>
        )
    }
}