import { Drawer, Avatar, Icon, Typography, Tooltip } from 'antd';
import React from 'react';
import '../../_styles/_shared.scss'
import API from '../../../utils/API'
import ProfileForm from './ProfileForm'
const { Text } = Typography;

export default class ProfileDrawer extends React.Component {
    
	constructor(props){
		super(props);


		this.state = {
			loading: true,
			user: null,
			organizations: null
		}
		
	}

	componentDidMount() {

		if(this.props.token !== null)
			this.getProfile();

	}

	componentDidUpdate(prevProps) {
		
		if(prevProps.token !== this.props.token && this.props.token !== null){
			this.getProfile();
		}

	}

	getProfile = () => {
		API.get("users/curr_user/", {
			headers : {"Authorization": "Token " + localStorage.getItem('token')}
		})
		.then(res => {
			this.setState(
				{
					user: res.data,
					loading: false
				}
			);
		})
	}

	hideDrawer = () => {
		this.props.setDrawerVisible("profile", false);
	}

	isShown = () => {
		return this.state.visible;
	}

	showDrawer = () => {
		this.props.setDrawerVisible("profile", true);
	}

	handleClose = () => {
		this.hideDrawer();
	}

	render() {
		return (
			<Drawer 
				title="Προφίλ Χρήστη"
				visible={this.props.visible}
				onClose={this.handleClose}
				width={400}
			>

				{this.state.loading ?
					<div><Icon type="loading" spin /></div>
				:
					<div className="flex-container">
						<div className="flex-column">
							{/* Avatar */}
							<div className="flex-row" style={{
									alignItems: "center",
									justifyContent: "center",
									backgroundColor: "#1890ff",
									padding: "1.5em",
									boxShadow: "3px 3px 13px -4px rgba(0,0,0,1)"
									}}>
								{this.state.user.profile.avatar === null ?
									<Avatar
										size={64}
										icon="user"
									/>
								: 
									<Avatar
										size={64}
										src={this.state.user.profile.avatar}
									/>
								}

								<div className="flex-column" style={{marginLeft: "2em"}}>
									<Text style={{color:"white"}}>{this.state.user.username}</Text>
									
									{(this.state.user.first_name || this.state.user.last_name) && 
										<Text type="secondary" style={{color:"white"}}><Icon type="user"/> {this.state.user.first_name} {this.state.user.last_name}</Text>
									}

									<Text type="secondary" style={{color:"white"}}><Icon type="mail"/> {this.state.user.email}</Text>
									<Text type="secondary" style={{color:"white"}}>
										<Icon type="bank"/> 
										<Tooltip title={this.state.user.profile.full_organization}>
											{" " + this.state.user.profile.organization}</Tooltip>
									</Text>
								</div>
							</div>

							<ProfileForm
								getProfile={this.getProfile}
								user={this.state.user}
							/>
						</div>
					</div>
				}
			</Drawer>
		);
	}
}
  