import React, { Component } from 'react'
import { Modal, Button, Input, Form, message, Select} from 'antd'
import {Typography} from 'antd';
import API from '../../utils/API'
import {errorHandler} from '../../utils/errorHandler';
import procedure_icon from '../../images/procedure_icon_small.png'
import EmailTags from '../Create/EmailTags'

const { Title } = Typography;
const { Option } = Select;

class EditProcedureModal extends Component {

    constructor(props) {
        super(props);

        this.state= {
            loading: false,
            procedures: [],
            projects: [],
            tags: [],
            selected_project: -1
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getProject = this.getProject.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.selected_procedure !== this.props.selected_procedure && this.props.selected_procedure !== undefined){
            this.getProject();
            this.setTagData();
        }
            
    }

    removeTag = (removedTag) => {
        let newTags = this.state.tags.filter(tag => tag !== removedTag)
        this.setState({tags: newTags})
    }

    addTag = (tag) => {
        let newTags = this.state.tags
        newTags.push(tag)
        this.setState({tags: newTags})
    }

    setTagData() {
        this.setState({tags : []}, 
            () => {
                var x
                for(x of this.props.selected_procedure.shared_with){
                    this.addTag(x)
            }
        })
        
    }

    handleOk = e => {
        this.props.setVisible(false);
    };

    handleCancel = e => {
        this.props.setVisible(false);
    };

    componentDidMount() {

        // Get all available procedures
        API.get("procs/")
        .then(res => {
            this.setState({procedures: res.data})
        })
        .catch(err => errorHandler(err))

        // Get all user's projects
        API.get("projects/owned_projects/", {
            headers : {"Authorization": "Token " + localStorage.getItem('token')}
        })
        .then(res => {
            this.setState({projects: res.data}, () => {
                // Delete default project from project list
                if(this.state.projects[0].default){
                    let temp = this.state.projects;
                    temp.shift()
                    this.setState({
                        projects : temp
                    }, () => {
                        this.getProject() 
                        this.setTagData()
                    })
                }
            })
        })
        .catch(err => errorHandler(err))
        
    }

    getMasterProcedure(){

        let procedure = []

        procedure = this.state.procedures.filter((procedure) => {
            return procedure.id === this.props.selected_procedure.master_procedure;
        })

        if(procedure[0] !== undefined)
            return procedure[0].title
        else 
            return ""

    }
    
    getProject(){

        let project = {}

        project = this.state.projects.find(project => project.id === this.props.selected_procedure.project)
        if(project === undefined || !this.props.selected_procedure.owned)
            this.setState({ selected_project : -1 })
        else
            this.setState({ selected_project : project.id })
    }

    // Handles all input text changes
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    // Handles form submission
    handleSubmit(){
        this.props.form.validateFields((e) => {
            if(!e){
                this.setState({ loading: true })

                API.patch("user_procedures/" + this.props.selected_procedure.id + "/", {
                    "custom_title": this.props.form.getFieldValue('title'),
                    "project": this.props.form.getFieldValue('project'),
                    "share_with": this.state.tags.length > 0 ? this.state.tags.join(',') : -1
                }, {
                    headers : {"Authorization": "Token " + localStorage.getItem('token')}
                })
                .then(() => {
                    message.success('Οι αλλαγές έγιναν με επιτυχία!');
                    this.setState({ loading: false });
                    this.props.refreshProcedureList();
                    this.props.setVisible(false);
                })
                .catch(err => errorHandler(err))
                
                this.setState({ loading : false });
            }
        })
    }

    render() {

        const { getFieldDecorator } = this.props.form

        return (
            <Modal
                footer={
                    <Button
                        type="primary"
                        loading={this.state.loading}
                        onClick={this.handleSubmit}
                        disabled={!this.props.selected_procedure.owned}
                    >
                        Αποθήκευση
                    </Button>
                }
                title="Επεξεργασία Διαδικασίας"
                visible={this.props.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
                <div className="flex-column" style={{alignItems:"center", justifyContent: "center"}}>


                    <div className="master-flex-row-create-section">
                        <div className="flex-column" style={{alignItems:"center", justifyContent: "center"}}>
                            <img src={procedure_icon} alt="procedure_icon" style={{width:"100%", maxWidth:"200px"}}></img>

                            <Title level={4}>Στοιχεία Διαδικασίας</Title>

                            <Form>

                                <Form.Item label="Τίτλος">
                                    {getFieldDecorator('title', {
                                        initialValue: this.props.selected_procedure.title
                                    })(
                                        <Input
                                            name="title"
                                            disabled={this.state.loading ||  !this.props.selected_procedure.owned}
                                            onChange={this.handleChange} 
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item label="Κοινοποίηση με">
                                    <EmailTags
                                        owned={this.props.selected_procedure.owned}
                                        tags={this.state.tags}
                                        removeTag={this.removeTag}
                                        addTag={this.addTag}
                                    />
                                </Form.Item>

                                <Form.Item label="Είδος Διαδικασίας" validateStatus={this.state.selected_procedure_error}>
                                    <Input disabled={true} defaultValue={this.getMasterProcedure()}/>
                                </Form.Item>

                                <Form.Item label="Επιλογή Έργου">
                                    {getFieldDecorator('project', {
                                        initialValue: this.state.selected_project
                                    })(
                                        <Select disabled={this.state.loading || !this.props.selected_procedure.owned}>
                                            <Option value={-1}>Κανένα</Option>
                                        
                                            {this.state.projects.map((item, i) => 
                                                <Option key={item.id} value={item.id}>{item.title}</Option>
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>
                                
                            </Form>
                        </div>

                    </div>

                </div>

            </Modal>
        )
    }
}


const WrappedProjectForm = Form.create()(EditProcedureModal)
export default WrappedProjectForm
