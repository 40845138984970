import React, { Component } from 'react'
import '../_styles/Dashboard.scss'
import {List} from 'antd';


export default class Statistics extends Component {

    state = {stats : undefined};

    // Get statistics from dashboard when this component mounts
    componentDidMount(){
        this.props.refreshStatistics();
        this.setState({stats : this.props.stats})
    }

    // Change stats whenever an update occurs
    componentDidUpdate(prevProps) {
        if(prevProps !== this.props){
            this.setState({stats : this.props.stats})
        }
    }

    render() {
        return (
            <List
                itemLayout="horizontal"
                size="small"
                split={false}
                dataSource={this.state.stats}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            title={<span className="unselectable-text" style={{color:item.color, fontWeight: item.style}}>{item.title}</span>}>
                        </List.Item.Meta>
                        
                        <span className="unselectable-text" style={{color:item.color}}>{item.number}</span>
                    </List.Item>
                )}
            />
        )
    }
}