import * as actionTypes from './actionTypes';
import API from '../../utils/API';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = token => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
    }
}

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    return {
        type: actionTypes.AUTH_LOGOUT
    }


}

export const authLogin = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        return API.post('auth/login/', {
            username: username,
            password: password
        })
        .then(res =>{
            const token = res.data.key;
            localStorage.setItem('token', token);
            dispatch(authSuccess(token));
        })
        .catch(err =>{
            dispatch(authFail(err));
        })
    }

}

    export const authSignup = (username, email, password1, password2) => {
        return dispatch => {
            dispatch(authStart());
            return API.post('auth/registration/', {
                username: username,
                email: email,
                password1: password1,
                password2: password2
            })
            .then(res =>{
                const token = res.data.key;
                localStorage.setItem('token', token);
                dispatch(authSuccess(token));
            })
            .catch(err =>{
                dispatch(authFail(err));
            })
        }
    }


    export const authCheckState = () => {
        return dispatch => {
            const token = localStorage.getItem('token');
            
            if (token === undefined)
                dispatch(logout());
            else
                dispatch(authSuccess(token));
        }
    }
