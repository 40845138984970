import React, { Component } from 'react'
import '../_styles/Home.scss'
import { Icon } from 'antd'

export default class ErrorPage extends Component {
    render() {
        return (
            <div id="flex-container">
                <div class="flex-row" style={{alignItems: 'center'}}>
                    <span style={{fontSize: '2em', borderBottom:"1px solid grey", width:'100%'}}>Ωχ! Τί έγινε εδώ; <Icon type="api" /></span>
                    <p id="text">
                        Προφανώς κάτι πήγε στραβά. Ίσως ο διακομιστής να μην είναι διαθέσιμος αυτή τη στιγμή ή μπορεί
                        να μην υπάρχει η διέυθυνση που ψάχνετε. 

                        <br></br>
                        <br></br>
                        Αν είστε σίγουρος για τη διαδρομή, προσπαθήστε ξανά αργότερα <Icon type="frown" />
                    </p>
                </div>
            </div>
        )
    }
}
