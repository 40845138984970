import React, { Component } from 'react'
import {Calendar, Modal, Typography, Button, message, Alert, Icon, Popconfirm} from 'antd'
import API from '../../utils/API'
import {errorHandler} from '../../utils/errorHandler';
import moment from 'moment'

export default class DeadlineCalendar extends Component {

    constructor(props){
        super(props);

        this.state = {
            date : moment(),
            loading : false
        }

        this.getDeadline = this.getDeadline.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount(){
        this.getDeadline()
    }

    componentDidUpdate(prevProps){
        if(prevProps !== this.props){
            this.getDeadline()
        }
    }

    onDateChange(selectedDate){
        this.setState({ date : moment(selectedDate) })
    }

    handleOk = e => {

        this.setState({ loading : true })

        let submittedDate

        if(this.state.date !== null)
            submittedDate = moment(this.state.date).format()
        else
            submittedDate = null

        API.patch('ctrl/update_history/' + this.props.selected_procedure.distinct_procedure + '/', {
            id : this.props.step.meta.id,
            deadline : submittedDate
        },
        {
            headers : {"Authorization": "Token " + localStorage.getItem('token')}
        })
        .then(res => {
            if(submittedDate !== null)
                message.success('Η προθεσμία ορίστηκε στις ' + moment(submittedDate).format('LL'))
            else
                message.success('Η προθεσμία αφαιρέθηκε με επιτυχία')
            this.setState({ loading : false })
            this.props.updateStep(this.props.parentProps);
            this.props.refreshProcedureList();
        })
        .catch(err => {
            errorHandler(err)
            this.setState({ loading : false })
        });

        this.props.setVisible(false);
    };

    handleCancel = e => {
        this.props.setVisible(false);
    };

    handleDelete = e => {
        this.setState({ date : null }, () => this.handleOk())
    }

    getTitle(){
        if(this.props.step !== undefined){
            if(Object.keys(this.props.step).length)
                return this.props.step !== undefined ? this.props.step.title : ""
        }
    }

    getDeadline(){
        if(this.props.step !== undefined){
            if(Object.keys(this.props.step).length)
                this.props.step.meta.deadline !== null ? this.setState({ date : moment(this.props.step.meta.deadline) }) : this.setState({ date : moment() })
        }
    }

    getCurrentDeadline(){
        if(this.props.step !== undefined){
            if(Object.keys(this.props.step).length)
                return this.props.step.meta.deadline !== null ? moment(this.props.step.meta.deadline).format('D MMMM YYYY') : null
        }
    }


    render() {
        return (
            <Modal
                visible={this.props.visible}
                closable={false}
                footer={
                    <div className="flex-row" style={{ justifyContent : "space-between"}}>

                        <Popconfirm title={"Είστε σίγουροι ότι θέλετε να αφαιρέσεται αυτή την προθεσμία;"} onConfirm={this.handleDelete} okText="Αφαίρεση" cancelText="Ακύρωση">
                            <Button 
                                tyle={{ fontSize : "12px" }} 
                                type="danger" icon="minus" 
                                disabled={this.getCurrentDeadline() === null}>
                                    Αφαίρεση προθεσμίας
                            </Button>
                        </Popconfirm>

                        <div>
                            <Button onClick={this.handleCancel}>Ακύρωση</Button>
                            <Button type="primary" onClick={this.handleOk}>Επιβεβαίωση</Button>
                        </div>
                    </div>
                }
                >
                    <Typography.Title level={4}>{"Προσθήκη ημερομηνίας προθεσμίας"}</Typography.Title>
                    <Typography.Text>{"Βήμα: \"" + this.getTitle() + "\""}</Typography.Text>
                    <br />
                    <br />

                    {this.state.loading ? 

                    <div className="flex-column" style={{alignContent : "center"}}>
                        <Icon style={{ fontSize : "36px" }} type="loading"/>
                    </div> 
                    
                    :

                    <div>

                        {(this.getCurrentDeadline() !== null && this.getCurrentDeadline() !== undefined) &&
                        <div>
                            <Alert message={"Επιλεγμένη Προθεσμία : " + this.getCurrentDeadline()} type="info" showIcon />
                        </div>
                        }
                        <Calendar 
                            validRange={[moment(),moment("21000101")]} 
                            fullscreen={false} defaultValue={this.state.date} 
                            onChange={(date) => this.onDateChange(date)}
                            value={this.state.date === null ? moment() : this.state.date}
                            />
                    </div>
                }
            </Modal>
        )
    }
}
