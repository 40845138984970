import React from 'react'
import {Typography} from 'antd';
import '../_styles/CreateSection.scss'
import Animate from 'rc-animate';
import project_icon from '../../images/project_icon_small.png'
import API from '../../utils/API';
import {
    Form,
    Input,
    Button,
    message
} from 'antd';

const { Title } = Typography;
const { TextArea } = Input;

class CreateProject extends React.Component {

    constructor(props) {
        super(props);
        
        this.state= {
            loading: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(event) {
        // Handles all input text changes
        this.setState({ [event.target.name]: event.target.value });
    }

    // Form Submission
    handleSubmit() {
        this.props.form.validateFields((e) => {
            if(!e) {
                this.setState({loading: true})

                API.post('projects/', {
                    "title": this.props.form.getFieldValue("title"),
                    "elke_code": this.props.form.getFieldValue("elke"),
                    "description": this.props.form.getFieldValue("description")
                }, {headers: {"Authorization": "Token " + localStorage.getItem('token')}
                })
                .then(() => {
                    message.success("Το έργο δημιουργήθηκε με επιτυχία")
                    this.setState({loading: false})

                    // Redirect to dashboard
                    this.props.history.push('/dashboard')
                })
                .catch(err => {
                    message.error(err.response.data.error);
                    this.setState({loading : false});
                })
            }
        })
    };

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <Animate
                transitionName="fade"
                transitionAppear
            >
                <div id="flex-container">
                    <Title level={3}>Δημιουργία Έργου</Title>

                    <div className="master-flex-row-create-section">
                        <div className="flex-column" style={{alignItems:"center", justifyContent: "center"}}>

                            <img src={project_icon} alt="project_icon"></img>

                            <Title level={4}>Στοιχεία Έργου</Title>

                            <Form>

                                <Form.Item label="Τίτλος" validateStatus={this.state.title_error}>
                                    {getFieldDecorator('title', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Ο τίτλος δεν μπορεί να είναι κενός',
                                                whitespace: true
                                            }
                                        ]
                                    })(
                                        <Input name="title" disabled={this.state.loading} onChange={this.handleChange} />
                                    )}
                                </Form.Item>

                                <Form.Item label="Κωδικός ΕΛΚΕ">
                                    {getFieldDecorator('elke')(
                                        <Input name="elke_code" disabled={this.state.loading} onChange={this.handleChange} />
                                    )}
                                </Form.Item>

                                <Form.Item label="Περιγραφή">
                                    {getFieldDecorator('description')(
                                        <TextArea rows={4} name="description" disabled={this.state.loading} onChange={this.handleChange} />
                                    )}
                                </Form.Item>

                                <Form.Item >
                                    <Button type="primary" loading={this.state.loading} onClick={this.handleSubmit}>
                                        Δημιουργία
                                    </Button>
                                </Form.Item>
                            
                            </Form>
                        </div>
                    </div>
                </div>
            </Animate>
        )
    }
}

const WrappedCreateProjectForm = Form.create()(CreateProject)
export default WrappedCreateProjectForm