import { Drawer, Table, Divider, Input, Button, Icon } from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';


const data=[
	{
		key:'O.01',
		title:'ΕΝΤΟΛΗ ΠΡΟΚΑΤΑΒΟΛΗΣ',
		docs:[
			{
				name:"ΕΝΤΟΛΗ ΠΡΟΚΑΤΑΒΟΛΗΣ",
				url:"https://elke.uom.gr/wp-content/uploads/2018/03/O.01-ΕΝΤΟΛΗ-ΠΡΟΚΑΤΑΒΟΛΗΣ.xls"
			}
		],
		type:"fin",
	},
	{
		key:'O.05',
		title:'ΕΝΤΟΛΗ ΠΛΗΡΩΜΗΣ-ΑΠΟΔΟΣΗ ΥΛΙΚΩΝ ΥΠΗΡΕΣΙΩΝ',
		docs:[
			{
				name:"ΕΝΤΟΛΗ ΠΛΗΡΩΜΗΣ-ΑΠΟΔΟΣΗ ΥΛΙΚΩΝ ΥΠΗΡΕΣΙΩΝ",
				url:"https://elke.uom.gr/wp-content/uploads/2018/03/O.05-ΕΝΤΟΛΗ-ΠΛΗΡΩΜΗΣ-ΑΠΟΔΟΣΗ-ΥΛΙΚΩΝ-ΥΠΗΡΕΣΙΩΝ.xls"
			},
			{
				name:"ΔΠΜΣ-εξοπλισμός-αναλώσιμα-λοιπά-έξοδα",
				url:"https://elke.uom.gr/wp-content/uploads/2018/03/Συνοδευτικός-Πίνακας-για-έντυπο-Ο.05-ΔΠΜΣ-_εξοπλισμός-αναλώσιμα-λοιπά-έξοδα-1.doc"
			}
		],
		type:"fin",
	},
	{
		key:'Δ',
		title:'Αιτήματα χρονικής παράτασης έργου',
		docs:[
			{
				name:"Αίτημα χρονικής παράσης φυσικού αντικειμένου έργου_έκδοση Μάρτιος 2018",
				url:"https://elke.uom.gr/wp-content/uploads/2018/02/Αίτημα-χρονικής-παράσης-φυσικού-αντικειμένου-έργου_έκδοση-Μάρτιος-2018.doc"
			},
			{
				name:"Αίτημα χρονικής παράσης οικονομικού αντικειμένου έργου_έκδοση Μάρτιος 2018",
				url:"https://elke.uom.gr/wp-content/uploads/2018/02/Αίτημα-χρονικής-παράσης-οικονομικού-αντικειμένου-έργου_έκδοση-Μάρτιος-2018.doc"
			},
			{
				name:"Αίτημα χρονικής παράσης φυσικού και οικονομικού αντικειμένου έργου_έκδοση Μάρτιος 2018",
				url:"https://elke.uom.gr/wp-content/uploads/2018/02/Αίτημα-χρονικής-παράσης-φυσικού-και-οικονομικού-αντικειμένου-έργου_έκδοση-Μάρτιος-2018.doc"
			}
		],
		type:"adm",
	},
	{
		key:'Δ6',
		title:'Ονομαστική Κατάσταση Απασχολούμενων με Ανάθεση Έργου',
		docs:[
			{
				name:"Δ6-Ονομαστική Κατάσταση Απασχολούμενων με Ανάθεση Έργου από συν. 200",
				url:"https://elke.uom.gr/wp-content/uploads/2018/02/Δ6-Ονομαστική-Κατάσταση-Απασχολούμενων-με-Ανάθεση-Έργου-από-συν.-200-1.xlsx"
			}
		],
		type:"adm"
	},
	{
		key:"Ατ01",
		title:"Ηλεκτρονική παρακολούθηση έργων",
		docs:[
			{
				name:"Ηλεκτρονική παρακολούθηση έργων από ΕΥ έκδοση 3",
				url:"https://elke.uom.gr/wp-content/uploads/2018/01/Aτ.01-Ηλεκτρονική-παρακολούθηση-έργων-έκδοση-3.xls"
			},
			{
				name:"Ηλεκτρονική παρακολούθηση έργων από Συνεργάτες έκδοση 3",
				url:"https://elke.uom.gr/wp-content/uploads/2018/01/Ατ.01α-Ηλεκτρονική-παρακολούθηση-έργων-από-Συνεργάτες-έκδοση-3.doc"
			}
		],
		type:"pet",
	},
	{
		key:"Ατ03",
		title:"Αίτ Χορ Προκ v 3_ΔΑΝΕΙΟ ΧΡΗΜ ΔΙΕΥΚΟΛ κατ εξαίρεση διατ Οδηγού Χρημ",
		docs:[
			{
				name:"Ηλεκτρονική παρακολούθηση έργων από ΕΥ έκδοση 3",
				url:"https://elke.uom.gr/wp-content/uploads/2018/01/Aτ.01-Ηλεκτρονική-παρακολούθηση-έργων-έκδοση-3.xls"
			},
			{
				name:"Ηλεκτρονική παρακολούθηση έργων από Συνεργάτες έκδοση 3",
				url:"https://elke.uom.gr/wp-content/uploads/2018/01/Ατ.01α-Ηλεκτρονική-παρακολούθηση-έργων-από-Συνεργάτες-έκδοση-3.doc"
			}
		],
		type:"pet",
	}
]

export default class DocumentsDrawer extends React.Component {
    
	constructor(props){
		super(props);

		this.state = {
			searchText: '',
			shrunk: false
		}
	}

	// Add resize listener
	componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	// Remove resize listener
	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
	}

	resize() {
		let toShrink = (window.innerWidth <= 760); // 760px is deemed to be the limit
		if (toShrink !== this.state.shrunk)
			this.setState({shrunk: toShrink})
	}
	
	hideDrawer = () => {
		this.props.setDrawerVisible("documents", false);
	}

	isShown = () => {
		return this.state.visible;
	}

	showDrawer = () => {
		this.props.setDrawerVisible("documents", true);
	}

	handleClose = () => {
		this.hideDrawer();
	}

	handleSearch = (selectedKeys, confirm) => {
		confirm();
		this.setState({ searchText: selectedKeys[0] });
	}

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	}

	handleChange = (pagination, filters, sorter) => {
		this.props.setDrawerFilter(filters);
	}

	render() {

		let categoryFilter = this.props.filter;
		categoryFilter = categoryFilter || {};

		const columns = [
			{
				title:"Τίτλος",
				dataIndex:"title",
				key:"title",
				filters:[
					{text:"Διοικητικά", value:"adm"},
					{text:"Οικονομικά", value:"fin"},
					{text:"Αιτήσεις", value:"pet"}
				],
				width: 400,
				filteredValue: categoryFilter.title || null,
				onFilter: (value, record) => record.type === value,
				render: (title, record) => (
					<div>
						<h4>{title}</h4>
					</div>
				)	
			},
			{
				title:"Έντυπα",
				dataIndex:"docs",
				key:"docs",
				filterDropdown: ({
					setSelectedKeys, selectedKeys, confirm, clearFilters,
				}) => (
					<div style={{ padding: 8 }}>
						<Input
							ref={node => { this.searchInput = node; }}
							placeholder={`Αναζήτηση εντύπου`}
							value={selectedKeys[0]}
							onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
							onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
							style={{ width: 188, marginBottom: 8, display: 'block' }}
						/>
						<Button
							type="primary"
							onClick={() => this.handleSearch(selectedKeys, confirm)}
							icon="search"
							size="small"
							style={{ width: 90, marginRight: 8 }}
						>
							Αναζητ.
						</Button>
						<Button
							onClick={() => this.handleReset(clearFilters)}
							size="small"
							style={{ width: 90 }}
						>
							Καθαρισμός	
						</Button>
					</div>
				),
				filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
				onFilterDropdownVisibleChange: (visible) => {
					if (visible) {
						setTimeout(() => this.searchInput.select());
					}
				},
				onFilter: (value, record) => record['docs'].map((doc) => {return doc.name.toLowerCase().includes(value.toLowerCase())}),
				render: docs => (
					docs.map((doc, idx) => {
						return (
							<div key={idx}>
								<a href={doc.url}>
									<Highlighter
										highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
										searchWords={[this.state.searchText].concat(this.state.searchText.split(' '))}
										autoEscape
										textToHighlight={doc.name}
									/>
								</a>
								{/* Display the divider on every element except the last */}
								{idx+1 !== docs.length && <Divider type="horizontal" />}
							</div>
						)
					})
				),
			},
		]


		return (
			<div>
				<Drawer
					title="Έντυπα"
					visible={this.props.visible}
					onClose={this.handleClose}
					afterVisibleChange={this.props.resetFilter}
					width={1000} // It will take up all of the screen eitherway hence why we don't check for "shrunk"
				>

					<Table
						columns={columns}
						dataSource={data}
						pagination={{pageSize:5}}
						scroll={{y:720}}
						onChange={this.handleChange}
						size={this.state.shrunk ? "small" : "medium"}
					/>
					
				</Drawer>
			</div>
		);
	}
}
  