import React, { Component } from 'react'
import '../_styles/Home.scss'
import {Button, Row, Col, Layout, Divider, message} from 'antd';
import Background from '../../images/home_bg_deep_blue.png'
import Logo from '../../images/schedio_logo.png'
import Schedule from '../../images/schedule_icon.svg'
import Timeline from '../../images/diagram.svg'
import Procedure from '../../images/procedure_square.svg'
import Project from '../../images/project_square.svg'
import Animate from 'rc-animate';
import { NavLink } from 'react-router-dom';
import ProfileDrawer from '../Sider/Profile/ProfileDrawer';



export default class Home extends Component {

    constructor(props){
        super(props);

        this.state = {
            profileDrawerShown: false
        }

    }

    setDrawerVisible = (drawer, vis) => {
        this.setState({ profileDrawerShown: vis })
    }

    registerMessage(){
        message.warning("Πρέπει να εγγραφείτε ή να συνδεθείτε πρώτα!")
    }

    render() {
        return (

            <Layout hasSider style={{ display: "flex", flexDirection: "column", height: "100vh"}}>

                <img id="background" alt="background" src={Background} style={{ height: "100%", width: "100%"}}></img>

                
                <Row type="flex" align="top" justify="center" style={{ height: "33%"}}>

                    <Col lg={7} xl={6} xxl={6} style={{ alignContent: "center" }}>
                        <img style={{ marginTop: "20px", maxWidth: "100%"}} src={Logo} alt="Logo"/>
                        <Animate
                            transitionName="fade"
                            transitionAppear
                        >
                            <div className="subtitle">Έυκολη διαχείρηση και παρακολούθηση<br/> ερευνητικών <red>έργων</red> και <blue>διαδικασιών</blue>.</div>
                        </Animate>
                    </Col>

                </Row>

                <Row type="flex" style={{ height: "33%"}}>

                </Row>


                <Row type="flex" style={{ marginTop: "auto", padding: "30px"}}>

                    <Col span={4} style={{ display : "flex", flexDirection : "column", justifyContent : "center", textAlign: "center"}}>
                        <span className="header-text">Δημιουργία<br/>Έργων</span>
                        <br/>

                        <img className="header-img" src={Project} alt="project icon"></img>

                    </Col>

                    <Col span={4} style={{ display : "flex", flexDirection : "column", justifyContent : "center", textAlign: "center"}}>
                        <span className="header-text">Έναρξη<br/>Διαδικασιών</span>
                        <br/>

                        <img className="header-img" src={Procedure} alt="procedure icon"></img>

                    </Col>

                    <Col span={4} style={{ display : "flex", flexDirection : "column", justifyContent : "center", textAlign: "center"}}>
                        <span className="header-text">Ορισμός<br/>Προθεσμιών</span>
                        <br/>

                        <img className="header-img" src={Schedule} alt="schedule icon"></img>
                    
                    </Col>

                    <Col span={4} style={{ display : "flex", flexDirection : "column", justifyContent : "center", textAlign: "center"}}>

                        <span className="header-text">Monitoring<br/>Χρονοδιαγράμματος</span>
                        <br/>

                        <img className="header-img" src={Timeline} alt="timeline icon"></img>

                    
                    </Col>

                    <Divider type="vertical" style={{ height: "100%"}}/>

                    <Col span={4} style={{ textAlign: "center", margin: "auto"}}>
                        <Row justify="center">
                            <span className="header-text">Εξερευνήστε το<br/> Κέντρο Ελέγχου</span>
                            <br/><br/>
                            {this.props.isAuthenticated ?
                            <NavLink to='/dashboard' >
                                <Button type="primary" size="large">Dashboard</Button>
                            </NavLink>

                            :
                            
                            <Button type="primary" size="large" onClick={this.registerMessage}>Dashboard</Button>
                            
                            }
                        </Row>
                        <Row justify="center" style={{ marginTop: "20px"}}>
                            <span className="header-text">Χρειάζεστε βοήθεια;</span>
                            <br/><br/>
                            <NavLink to="/help_page">
                                <Button type="danger" size="large">Βοήθεια</Button>
                            </NavLink>
                        </Row>
                    
                    </Col>

                    <Divider type="vertical" style={{ height: "100%"}}/>

                    <Col span={3} style={{ textAlign: "center", margin: "auto"}}>
                    <Row justify="center">
                            <span className="header-text">Εγγραφείτε<br/>απλά και γρήγορα</span>
                            <br/><br/>
                            <NavLink to='/register' >
                                <Button type="primary" size="large">Εγγραφή</Button>
                            </NavLink>
                        </Row>
                        <Row justify="center" style={{ marginTop: "20px"}}>
                            {this.props.isAuthenticated ?
                                <div>
                                    <span className="header-text">Είστε ήδη συνδεδεμένος</span>
                                    <br/><br/>
                                    <Button style={{ color: "#1890ff" }} size="large" onClick={() => this.setDrawerVisible("profile", true)}>Προφίλ</Button>
                                </div>
                            :
                                <div>
                                    <span className="header-text">Είστε ήδη εγγεγραμμένος;</span>
                                    <br/><br/>
                                    <NavLink to='/login' >
                                        <Button style={{ color: "#1890ff" }} size="large">Σύνδεση</Button>
                                    </NavLink>
                                </div>
                            }
                        </Row>
                    
                    </Col>

                </Row>

                <ProfileDrawer
                    token={localStorage.getItem('token')}
                    visible={this.state.profileDrawerShown}
                    setDrawerVisible={this.setDrawerVisible}
                />
                

            </Layout>
            
        )
    }
}
