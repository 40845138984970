import React, { Component } from 'react'
import { Modal, Button, Descriptions   } from 'antd'
import {Typography} from 'antd';
import API from '../../utils/API'
import {errorHandler} from '../../utils/errorHandler';
import project_icon from '../../images/project_icon_small.png'

import EditProjectModal from './EditProjectModal'

const { Title } = Typography;

export default class ViewProjectModal extends Component {

    constructor(props) {
        super(props);

        this.state= {
            project: {},
            editProjectModalVisible : false
        }


        this.editProjectModalVisible = this.editProjectModalVisible.bind(this);
        this.showEditProjectModal = this.showEditProjectModal.bind(this);
    }

    componentDidMount(){
        if(this.props.project !== undefined){
            this.getProject();
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.project !== this.props.project){
                this.getProject();
        }
    }

    getProject(){
        API.get('projects/' + this.props.project + '/',   {
            headers : {"Authorization": "Token " + localStorage.getItem('token')}
        })
        .then(res => {
            this.setState({
                project : res.data
            })
        })
        .catch(err => errorHandler(err))
    }

    editProjectModalVisible(visible) {
        this.setState({ editProjectModalVisible: visible });
        this.getProject();
    }

    showEditProjectModal() {
        this.editProjectModalVisible(true);
    }

    handleOk = e => {
        this.props.setVisible(false);
    };

    handleCancel = e => {
        this.props.setVisible(false);
    };

    render() {
        return (
            <div>
                <EditProjectModal
                    visible={this.state.editProjectModalVisible}
                    setVisible={this.editProjectModalVisible}
                    project={this.state.project}
                    refreshProjectList={this.props.refreshProjectList}>
                </EditProjectModal>
                <Modal
                    footer={[<div key={0}>{!this.state.project.default && <Button type="danger" disabled={!this.props.owned} onClick={this.showEditProjectModal}>Επεξεργασία Έργου</Button>}</div>]}
                    title="Πληροφορίες έργου"
                    visible={this.props.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <div className="flex-column" style={{alignItems:"center", justifyContent: "center"}}>

                        <img src={project_icon} alt="project_icon"></img>
                        {this.state.project.default ? 
                        <Title level={4}>Η διαδικασία δεν ανήκει σε κάποιο έργο</Title>
                    
                        :

                        <div className="flex-column" style={{alignItems:"center", justifyContent: "center"}}>
                            <Title level={4} style={{marginTop : "10px"}}>Πληροφορίες Έργου</Title>
                            <Descriptions bordered column={1} layout="horizontal" style={{marginTop : "15px"}}>
                                <Descriptions.Item label="Τίτλος">{this.state.project.title}</Descriptions.Item>
                                <Descriptions.Item label="Κωδικός Έλκε">{this.state.project.elke_code}</Descriptions.Item>
                                <Descriptions.Item label="Περιγραφή">{this.state.project.description}</Descriptions.Item>
                            </Descriptions>
                        </div>

                        
                        }
                    </div>
                </Modal>
            </div>
        )
    }
}
