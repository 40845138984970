import React, { Component } from 'react'
import moment from 'moment'
import '../_styles/Dashboard.scss'
import { Button, Collapse, Skeleton, Checkbox, List, Icon, Tooltip, Row, Col } from 'antd'
import API from '../../utils/API'
import {errorHandler} from '../../utils/errorHandler';
import NoteModalMaster from './NoteModalMaster'
import Calendar from './DeadlineCalendar'
import { omit } from '../../utils/helpers'

const { Panel } = Collapse;


export default class CurrentStep extends Component {

    constructor(props) {
        super(props);

        this.state = {
            step: {},
            substeps : undefined,
            loading : false,
            substepsLoading : false,
            noteModalVisible: false,
            noteData: [],
            calendarVisible : false,
            deadlineStep : {}
        }

        this.handleNextClick = this.handleNextClick.bind(this);
        this.updateStep = this.updateStep.bind(this);
        this.updateTotalSteps = this.updateTotalSteps.bind(this);
        this.noteModalVisible = this.noteModalVisible.bind(this);
        this.showNoteModal = this.showNoteModal.bind(this);
        this.calendarVisible = this.calendarVisible.bind(this);
        this.showCalendar = this.showCalendar.bind(this);
    }



    // Refresh state whenever update occurs
    componentDidUpdate(prevProps){
        if(prevProps.selected_procedure !== this.props.selected_procedure || prevProps.selected_step !== this.props.selected_step){
            this.updateStep(this.props);
            // [CAN OPTIMIZE SO IT'S CALLED ONLY ON OCCASIONS]
            this.updateTotalSteps(this.props);
        }
    }

    /* This function triggers when "next step" click is pressed.
    * Informs api and calls dashboard's function "refreshProcedureList()" 
    * to refresh all sub-components.
    */
    handleNextClick() {
        if(this.props.selected_step > 0) {
            this.props.onNextStep();
        }
        else {
            API.post('ctrl/next_step/', {
                distinct_proc : this.props.selected_procedure.distinct_procedure,
                end_date : moment().format()
            } ,{
                headers : {"Authorization": "Token " + localStorage.getItem('token')}
            })
                .then(res => {
                    // Loading session starts from here, calling dashboard to update data.
                    // Will end when all api calls end.
                    this.setState({loading : true});
                    this.props.resetStep();
                    this.props.refreshProcedureList();
                })
                // Catch errors and translate them
                .catch(err => errorHandler(err));
        }
        
    }

    updateStep(nextProps){
        if(nextProps !== undefined){
            if(nextProps.selected_procedure !== undefined){
                this.setState({loading : true});


                API.get('ctrl/curr_step_offset/' + nextProps.selected_procedure.distinct_procedure + '/' + this.props.selected_step + '/', {
                    headers : {"Authorization": "Token " + localStorage.getItem('token')}
                })
                .then(res => {
                    // Update current step
                    this.setState({
                        step: omit(res.data, 'substeps')
                    });

                    // If this step has substeps, update them as well
                    if(res.data.substeps !== null){
                        this.setState({substeps : res.data.substeps});
                    }
                    else {
                        this.setState({substeps : undefined});
                    }

                    this.setNoteData();
                    this.setState({loading : false})
                })
                .catch(err => {
                    errorHandler(err)
                    this.setState({loading : false})
                });
            }
        }
    }

    // Updates total steps field of the given procedure
    updateTotalSteps(nextProps){
        if(nextProps.selected_procedure !==undefined){
            API.get('procs/' + nextProps.selected_procedure.master_procedure + '/', {
                headers : {"Authorization": "Token " + localStorage.getItem('token')}
            })
            .then(res => {
                this.setState({total_proc_steps : res.data.total_steps})
            })
            .catch(err => errorHandler(err));
        }
    }

    // Inform api for substep completion changes
    checkboxChange(item) {
        this.setState({substepsLoading : true});

        let date = null
        if(!item.completed)
            date = moment().format()

        API.patch('ctrl/update_history/' + this.props.selected_procedure.distinct_procedure + '/', {
            id : item.meta.id,
            completed : !item.completed,
            end_date : date
        },
        {
            headers : {"Authorization": "Token " + localStorage.getItem('token')}
        })
        .then(() => {
            this.updateStep(this.props);
            this.setState({substepsLoading : false});
            this.props.refreshProcedureList();
        })
        .catch(err => errorHandler(err))
    }

    noteModalVisible(visible) {
        this.setState({ noteModalVisible: visible });
    }

    showNoteModal() {
        this.noteModalVisible(true);
    }

    calendarVisible(visible, step) {
        this.setState({ 
            deadlineStep : step,
            calendarVisible : visible });
      };

    showCalendar(step){
        this.calendarVisible(true, step);
    }

    setNoteData() {
        let data = [];
        let mainStep = this.state.step;

        if(mainStep !== undefined && mainStep !== null && mainStep !== {}) {

            data.push(this.noteStepObjectData(mainStep));

            if(this.state.substeps !== undefined && this.state.substeps.length > 0) {
                this.state.substeps.forEach((v, i) => {
                    data.push(this.noteStepObjectData(v));
                })
            }

        }

        this.setState({ noteData: data });
    }

    noteStepObjectData(step) {
        return {
            id: step.id,
            title: step.title,
            note: step.note
        }
    }

    hasDeadline(main, subStep){
        if(main){
            if('meta' in this.state.step){
                if(this.state.step.meta.deadline !== null)
                    return true
            }
        }
        else{
            if('meta' in subStep){
                if(subStep.meta.deadline !== null)
                    return true
            }
        }

        return false

    }

    render() {
        if(this.props.selected_procedure === undefined)
            return (
                <Skeleton></Skeleton>
            )
        else {
            return (
                <Row className="box-container" align="middle">
                
                    <Calendar 
                        visible={this.state.calendarVisible}
                        setVisible={this.calendarVisible}
                        step={this.state.deadlineStep}
                        selected_procedure={this.props.selected_procedure}
                        updateStep={this.updateStep}
                        refreshProcedureList={this.props.refreshProcedureList}
                        parentProps={this.props}
                        >
                    </Calendar>

                    {this.state.loading === false && 
                        <NoteModalMaster
                            visible={this.state.noteModalVisible}
                            setVisible={this.noteModalVisible}
                            data={this.state.noteData}
                            selected_procedure={this.props.selected_procedure}
                            updateStep={this.updateStep}
                        />
                    }

                    <Row type="flex" justify="space-between" align="middle">
                        <div className="header">Τωρινό Βήμα</div>
                        <div className="header">
                            <Button
                                icon="solution" style={{marginRight: '1em'}}
                                onClick={this.showNoteModal}
                            />
                            {this.props.selected_procedure.steps_completed - this.props.selected_step} / {this.state.total_proc_steps}</div>
                    </Row>

                    <hr className="hr2"/>

                    <Col>
                    <Collapse defaultActiveKey={['1']}  bordered={false}>
                        <Panel header={
                            <div>
                                {this.state.step.title}
                                {this.props.selected_step !== 0 && !this.state.loading ?
                                <Tooltip title={"Αυτό το βήμα έχει ολοκληρωθεί"}>
                                    <Icon style={{marginLeft: "7px"}} type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                                </Tooltip>
                                :
                                <Tooltip title={this.hasDeadline(true) ? "Προβολή προθεσμίας" : "Προσθήκη προθεσμίας"} >
                                    <Button style={{marginLeft : "7px"}} type={this.hasDeadline(true) && "primary"} size="small" icon="calendar" onClick={() => this.showCalendar(this.state.step)}/>
                                </Tooltip>
                                }
                            </div>
                            } 
                            key="1">
                            <p>{this.state.step.description}</p>
                        </Panel>
                    </Collapse>

                        {/* If theres no substeps -> Hide substep Panel */}
                        {this.state.substeps !== undefined &&

                        <Collapse defaultActiveKey={['1']}  bordered={false}>
                            <Panel header="Υποβήματα">
                                <List
                                itemLayout="horizontal"
                                dataSource={this.state.substeps}
                                renderItem={item => (
                                    <List.Item>
                                        <Checkbox 
                                            checked={item.completed} 
                                            disabled={!this.props.selected_procedure.visible || 
                                                this.state.substepsLoading || 
                                                this.props.selected_step !== 0} 
                                            onChange={() => this.checkboxChange(item)}>
                                                {item.title}
                                        </Checkbox>
                                        <Tooltip title={this.hasDeadline(false, item) ? "Προβολή προθεσμίας" : "Προσθήκη προθεσμίας"}>
                                            <Button disabled={item.completed} type={this.hasDeadline(false, item) && "primary"} size="small" icon="calendar" onClick={() => this.showCalendar(item)}/>
                                        </Tooltip>
                                    </List.Item>
                                    )}
                                />
                            </Panel>
                        </Collapse>

                        }
                        <Row type="flex" align="middle" justify="space-around" style={{ margin : "10px" }}>
                            <Tooltip title={"Προηγούμενο"} >
                                <Button
                                    type="primary"
                                    icon={"left-circle"} 
                                    disabled={!this.props.selected_procedure.visible || this.props.selected_procedure.steps_completed === this.props.selected_step} 
                                    onClick={this.props.onPreviousStep}>
                                        {this.state.previousButtonText}
                                </Button>
                            </Tooltip>
                            <Button
                                type="primary" 
                                size="large"
                                disabled={!this.props.selected_procedure.visible || 
                                        (this.props.selected_procedure.steps_completed === this.state.total_proc_steps && this.props.selected_step === 0)
                                        } 
                                onClick={this.handleNextClick}>
                                    Επόμενο
                                    <Icon type="right-circle"></Icon>
                            </Button>
                            <Tooltip title={"Επιστροφή στο τωρινό βήμα"} visible={this.props.selected_step !== 0}>
                                <Button disabled={this.props.selected_step === 0} icon={"forward"} onClick={this.props.resetStep}></Button>
                            </Tooltip>
                        </Row>
                    </Col>
                </Row>
            )
        }
    }
}