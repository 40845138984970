import React, { Component } from 'react'
import { Modal, Button, Input, Form, message} from 'antd'
import {Typography} from 'antd';
import API from '../../utils/API'
import {errorHandler} from '../../utils/errorHandler';
import project_icon from '../../images/project_icon_small.png'

const { TextArea } = Input;
const { Title } = Typography;

class EditProjectModal extends Component {

    constructor(props) {
        super(props);

        this.state= {
            loading: false,
            project: {}
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps){
        
        if(prevProps.project !== this.props.project && this.props.form !== undefined)
            this.props.form.setFieldsValue({
                    title : this.props.project.title,
                    elke : this.props.project.elke_code,
                    description : this.props.project.description
            })
    }

    handleChange(event) {
        // Handles all input text changes
        this.setState({ [event.target.name]: event.target.value });
    }

    handleOk = e => {
        this.props.setVisible(false);
    };

    handleCancel = e => {
        this.props.setVisible(false);
    };

    handleSubmit() {
        this.props.form.validateFields((e) => {
            if(!e) {
                this.setState({loading: true})

                API.patch('projects/' + this.props.project.id + '/',
                    {
                        "title": this.props.form.getFieldValue("title"),
                        "elke_code": this.props.form.getFieldValue("elke"),
                        "description": this.props.form.getFieldValue("description")
                    }, {headers: {"Authorization": "Token " + localStorage.getItem('token')}
                })
                .then(() => {
                    message.success("Οι αλλαγές έγιναν με επιτυχία")
                    this.handleOk();
                    this.props.refreshProjectList();
                })
                .catch(err => errorHandler(err))

                this.setState({loading: false})
            }
        })
    };

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                footer={[<div key={0}>{!this.props.project.default && <Button key="submit" type="primary" onClick={this.handleSubmit}>Αποθήκευση</Button>}</div>]}
                title="Πληροφορίες έργου"
                visible={this.props.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
                <div className="flex-column" style={{alignItems:"center", justifyContent: "center"}}>

                    <img src={project_icon} alt="project_icon"></img>
                    {this.props.project.default ? 
                    <Title level={4}>Η διαδικασία δεν ανήκει σε κάποιο έργο</Title>
                
                    :

                    <Form>

                        <Form.Item label="Τίτλος" validateStatus={this.state.title_error}>
                            {getFieldDecorator('title', {
                                initialValue: this.props.project.title
                                }, 
                                {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Ο τίτλος δεν μπορεί να είναι κενός',
                                        whitespace: true
                                    }
                                ]
                            })(
                                <Input name="title" disabled={this.state.loading} onChange={this.handleChange} />
                            )}
                        </Form.Item>

                        <Form.Item label="Κωδικός ΕΛΚΕ">
                            {getFieldDecorator('elke', {
                                initialValue: this.props.project.elke_code
                                })(
                                <Input name="elke_code" disabled={this.state.loading} onChange={this.handleChange} />
                            )}
                        </Form.Item>

                        <Form.Item label="Περιγραφή">
                            {getFieldDecorator('description', {
                                initialValue: this.props.project.description
                                })(
                                <TextArea rows={4} name="description" disabled={this.state.loading} onChange={this.handleChange} />
                            )}
                        </Form.Item>
                    
                    </Form>
                    }
                </div>
            </Modal>
        )
    }
}


const WrappedProjectForm = Form.create()(EditProjectModal)
export default WrappedProjectForm
