import React from 'react';
import { connect} from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/auth';
import {  Menu, Icon, Layout } from 'antd';
import { succ_logout } from '../_shared/messages';
import DocumentsDrawer from './DocumentsDrawer';
import ProfileDrawer from './Profile/ProfileDrawer';

const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

class SiderMenu extends React.Component{
    
    constructor(props){
        super(props);

        this.state = {
            collapsed: false,
            selection: this.getSelectedKey(),
            documentsDrawerShown: false,
            documentsDrawerFilter: {},
            profileDrawerShown: false
        }
    }

    getSelectedKey = () => {
        let path = this.props.location.pathname;
        let custSelection = this.props.history.state;
    
        let fallbackSelection = /\/(\w*)\/?/.exec(path)[1];

        if(custSelection !== undefined)
            return custSelection.selection;
        else
            if(path === '/')
                return 'home';
            else if(path.includes('create') || path.includes('add'))
                return 'dashboard';
            else
                return fallbackSelection;
    }
    
    onCollapse = (collapsed) => {
        this.setState({ collapsed });
    }

    // Expects an array of values οr an object
    setDrawerFilter = (filter) => {

        let filterObj = null;

        // If parameter is not null
        if(filter !== null)

            // And it is an object
            if(typeof filter === "object")
                filterObj = filter; // Set it as-is
            else
			    filterObj = {"title":[filter]}; // If not, build it anew
        
        this.setState({ documentsDrawerFilter: filterObj });
    }

    resetDrawerFilter = () => {
        this.setState({ documentsDrawerFilter: null });
    }

    setDrawerVisible = (drawer, vis) => {
        if(drawer === "documents")
            this.setState({ documentsDrawerShown: vis });
        else
            this.setState({ profileDrawerShown: vis })
    }

    handleDrawerSelection = (filter) => {
        if(!this.state.documentsDrawerShown){
            this.setDrawerFilter(filter);
            this.setDrawerVisible("documents", true);
        }
    }

    render(){
        return (
            <Sider
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
            breakpoint={'xxl'}
            >
                <Menu 
                theme="dark"
                mode="inline"
                selectedKeys={[this.getSelectedKey()]}
                >

                    <Menu.Item key="home" >
                        <Icon type="home" />
                        <span className="nav-text">Αρχική</span>
                        <NavLink to='/' ></NavLink>
                    </Menu.Item>

                    {this.props.isAuthenticated &&
                        [
                            <Menu.Item key="dashboard" >
                                <Icon type="bar-chart" />
                                <span className="nav-text">Dashboard</span>
                                <NavLink to='/dashboard' ></NavLink>
                            </Menu.Item>
                        ,
                            <Menu.Item key="profile" onClick={() => this.setDrawerVisible("profile", true)}>
                                <Icon type="user" />
                                <span className="nav-text">Προφίλ</span>
                            </Menu.Item>
                        ]
                    }

                    {!this.props.isAuthenticated && 
                    
                        [
                            <Menu.Item key="login">
                                <Icon type="user" />
                                <span className="nav-text">Σύνδεση</span>
                                <NavLink to='/login'></NavLink>
                            </Menu.Item>
                        ,
                            <Menu.Item key="register">
                                <Icon type="user-add" />
                                <span className="nav-text">Εγγραφή</span>
                                <NavLink to='/register'></NavLink>
                            </Menu.Item>
                        ]
                    }

                    <SubMenu key="documents" title={
                        <span>
                            <Icon type="audit" />
                            <span className="nav-text">Έντυπα</span>
                        </span>
                    }>
                        
                        <Menu.Item key="documents_all" onClick={() => this.handleDrawerSelection(null)}>Όλα</Menu.Item>
                        <Menu.Item key="documents_adm" onClick={() => this.handleDrawerSelection("adm")}>Διοικητικά</Menu.Item>
                        <Menu.Item key="documents_fin" onClick={() => this.handleDrawerSelection("fin")}>Οικονομικά</Menu.Item>
                        <Menu.Item key="documents_pet" onClick={() => this.handleDrawerSelection("pet")}>Αιτήσεις</Menu.Item>

                    </SubMenu>

                    <Menu.Item key="help_page">
                        <Icon type="question-circle" />
                        <span className="nav-text">Βοήθεια</span>
                        <NavLink to="/help_page"></NavLink>
                    </Menu.Item>

                    {this.props.isAuthenticated && 
                        <Menu.Item key="logout" onClick={this.props.logout}>
                                <Icon type="logout" />
                                <span className="nav-text">Αποσύνδεση</span>
                                <NavLink to="/" onClick={succ_logout}></NavLink>
                        </Menu.Item>
                    }
                </Menu>
                
                <DocumentsDrawer
                    visible={this.state.documentsDrawerShown}
                    filter={this.state.documentsDrawerFilter}
                    setDrawerVisible={this.setDrawerVisible}
                    setDrawerFilter={this.setDrawerFilter}
                    resetDrawerFilter={this.resetDrawerFilter}
                />

                <ProfileDrawer
                    token={localStorage.getItem('token')}
                    visible={this.state.profileDrawerShown}
                    setDrawerVisible={this.setDrawerVisible}
                />
            </Sider>
        )
    }
}

const MapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout())
    }
}

export default withRouter(connect(null, MapDispatchToProps)(SiderMenu));
