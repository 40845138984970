import React from 'react';
import { Layout } from 'antd';
import './Layout.scss'
import SiderMenu from '../components/Sider/SiderMenu';

const { Content } = Layout;

export default class CustomLayout extends React.Component {
    render() {
        return (
            <Layout className="full-screen">
                <SiderMenu {...this.props}/>
                <Content>
                    <div>
                        {this.props.children}
                    </div>
                </Content>
            </Layout>
        );
    }
}