import React, { Component } from 'react'
import {Typography} from 'antd'
import '../_styles/CreateSection.scss'
import Animate from 'rc-animate'
import procedure_icon from '../../images/procedure_icon_small.png'
import API from '../../utils/API'
import EmailTags from './EmailTags'
import {
    Form,
    Input,
    Select,
    Button,
    message
  } from 'antd';


const { Option } = Select;
const { Title } = Typography;

class CreatePrProcedure extends Component {

    constructor(props) {
        super(props);
        
        this.state= {
            loading: false,
            procedures: [],
            projects: [],
            tags: []
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    removeTag = (removedTag) => {
        let newTags = this.state.tags.filter(tag => tag !== removedTag)
        this.setState({tags: newTags})
    }

    addTag = (tag) => {
        let newTags = this.state.tags
        newTags.push(tag)
        this.setState({tags: newTags})
    }

    componentDidMount() {

        // Get all available procedures
        API.get("procs/")
        .then(res => {
            this.setState({procedures: res.data})
        });

        // Get all user's projects
        API.get("projects/owned_projects/", {
            headers : {"Authorization": "Token " + localStorage.getItem('token')}
        })
        .then(res => {
            this.setState({projects: res.data}, () => {
                // Delete default project from project list
                if(this.state.projects[0].default){
                    let temp = this.state.projects;
                    temp.shift()
                    this.setState({
                        projects : temp
                    })
                }
            })
        });
        
    }

    // Handles all input text changes
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    // Handles form submission
    handleSubmit(){
        this.props.form.validateFields((e) => {
            if(!e){
                this.setState({ loading: true })

                API.post("user_procedures/", {
                    "custom_title": this.props.form.getFieldValue('title'),
                    "master_procedure": this.props.form.getFieldValue('master_procedure'),
                    "project": this.props.form.getFieldValue('project') === -1 ? undefined : this.props.form.getFieldValue('project'),
                    "share_with": this.state.tags.length > 0 ? this.state.tags.join(',') : undefined
                }, {
                    headers : {"Authorization": "Token " + localStorage.getItem('token')}
                })
                .then(() => {

                    message.success('Η Διαδικασία δημιουργήθηκε και αποθηκεύτηκε με επιτυχία!');
                    this.setState({loading: false});

                    // Redirect to Dashboard
                    this.props.history.push('/dashboard')
                })
                .catch(err => {
                    message.error(err.response.data.error);
                });
                this.setState({ loading : false });
            }
        })
    }

    render() {

        const { getFieldDecorator } = this.props.form

        return (
            <Animate
                transitionName="fade"
                transitionAppear
            >

                <div id="flex-container">
                    <Title level={3}>Έναρξη Διαδικασίας</Title>

                    <div className="master-flex-row-create-section">
                        <div className="flex-column" style={{alignItems:"center", justifyContent: "center"}}>
                            <img src={procedure_icon} alt="procedure_icon" style={{width:"100%", maxWidth:"200px"}}></img>

                            <Title level={4}>Στοιχεία Διαδικασίας</Title>

                            <Form>

                                <Form.Item label="Τίτλος">
                                    {getFieldDecorator('title')(
                                        <Input name="title" disabled={this.state.loading} onChange={this.handleChange} />
                                    )}
                                </Form.Item>

                                <Form.Item label="Κοινοποίηση με">
                                    <EmailTags
                                        tags={this.state.tags}
                                        removeTag={this.removeTag}
                                        addTag={this.addTag}
                                    />
                                </Form.Item>

                                <Form.Item label="Επιλογή Διαδικασίας" validateStatus={this.state.selected_procedure_error}>
                                    {getFieldDecorator('master_procedure', {
                                        rules: [{required: true, message: "Η διαδικασία δεν μπορεί να είναι κενή"}]
                                    })(
                                        <Select disabled={this.state.loading} onChange={this.handleProcedureChange}>
                                            {this.state.procedures.map((item, i) => 
                                                <Option key={item.id} value={item.id}>{item.title}</Option>
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Επιλογή Έργου">
                                    {getFieldDecorator('project', {
                                        initialValue: -1
                                    })(
                                        <Select disabled={this.state.loading}>
                                            <Option value={-1}>Κανένα</Option>
                                        
                                            {this.state.projects.map((item, i) => 
                                                <Option key={item.id} value={item.id}>{item.title}</Option>
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item >
                                    <Button type="primary" loading={this.state.loading} onClick={this.handleSubmit}>
                                        Αποθήκευση
                                    </Button>
                                </Form.Item>
                                
                            </Form>
                        </div>

                    </div>

                </div>
            </Animate>
        )
    }
}

const WrappedCreatePrProcedureForm = Form.create()(CreatePrProcedure)
export default WrappedCreatePrProcedureForm