import { Modal, message } from 'antd';
import  React, { Component }  from 'react';
import TextArea from 'antd/lib/input/TextArea';
import API from '../../utils/API'
import {errorHandler} from '../../utils/errorHandler';

export default class NoteTextModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            content: "",
            loading: false
        }
    }

    handleOk = e => {
        this.setState({ loading: true });

        API.patch("ctrl/update_note/" + this.props.step.id + '/', {
            "content": this.state.content
        }, {
            headers : {"Authorization": "Token " + localStorage.getItem('token')}
        })
        .then(res => {
            message.success("Η σημείωση ενημερώθηκε");
            this.props.updateStep(this.props);
            this.props.setVisible(false);
        })
        .catch(err => errorHandler(err))

        this.setState({ loading : false })
        
    };

    handleCancel = e => {
        this.setState({ loading: false });
        this.props.setVisible(false);
    };

    handleChange = (e) => {
        this.setState({ content: e.target.value });
    }

    render() {
        return (
            <Modal
                title="Επεξεργασία Σημείωσης"
                visible={this.props.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                confirmLoading={this.state.loading}
                okText="ΟΚ"
                cancelText="Άκυρο"
            >
                <TextArea
                    rows={4}
                    onChange={this.handleChange}
                    defaultValue={this.props.step !== null ? this.props.step.note : this.state.content}
                />
            </Modal>
        );
    }
}
