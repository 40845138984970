import React, { Component } from 'react'
import ProcedureList from './ProcedureList'
import ProcedureDetails from './ProcedureDetails'
import ProjectList from './ProjectList'
import Statistics from './Statistics'
import '../_styles/Dashboard.scss'
import {Button, Typography, Switch, Row, Col, Layout, Divider} from 'antd';
import API from '../../utils/API';
import {errorHandler} from '../../utils/errorHandler';
import { NavLink} from 'react-router-dom';
import Animate from 'rc-animate';


const { Text } = Typography;

export default class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selected_procedure: undefined,
            procedures : undefined,
            selected_project : undefined,
            projects : [],
            showArchived : false,

            //All status have an init quantity of 0.
            //The only thing that changes per user is "number" field
            stats : [
                {
                    title :'Διαδικασίες',
                    color : '#1890ff',
                    number : 0,
                    style : 'bold'
                },
                {
                    title :'Ενεργές',
                    color : '#5c5c5c',
                    number : 0
                },{
                    title :'Ολοκληρωμένες',
                    color : '#5c5c5c',
                    number : 0
                },{
                    title :'Αρχειοθετημένες',
                    color : '#5c5c5c',
                    number : 0
                },
                {
                    title :'Έργα',
                    color : '#ff7875',
                    number : 0,
                    style : 'bold'
                },
                {
                    title :'Ενεργά',
                    color : '#5c5c5c',
                    number : 0
                },
                {
                    title :'Ανενεργά',
                    color : '#5c5c5c',
                    number : 0
                }
            ]
        }

        this.handleProcedureSelection = this.handleProcedureSelection.bind(this);
        this.refreshProcedureList = this.refreshProcedureList.bind(this);
        this.refreshStatistics = this.refreshStatistics.bind(this);
        this.refreshProjectList = this.refreshProjectList.bind(this);
        this.onShowHideArchived = this.onShowHideArchived.bind(this);
    }

    // Refresh Procedure List data when component is mounted
    componentDidMount(){
        this.refreshProcedureList();
    }

    // Sets selected procedure, as its picked at ProcedureList component
    handleProcedureSelection = (item) => {
        this.setState({selected_procedure: item});
    }

    // Sets selected projet, as its picked at ProjectList component
    handleProjectSelection = (item) => {
        this.setState({selected_project : item});
    }

    // Really important method! Have to be called after every db change!
    // Refreshes list with updated data everytime user does POST, PATCH or PUT
    refreshProcedureList(){
        this.setState({loading : true});

        // Get an updated Procedure List from api
        API.get("user_procedures/", {
            headers : {"Authorization": "Token " + localStorage.getItem('token')}
        })
        .then(res => {
            // Replace previous Procedure List with the updated one
            this.setState({ procedures: res.data["owned"].concat(res.data["shared"])});
            this.setState({ loading: false });
            
            /* ~Update Selected Procedure~
            * If the previous selected procedure is not deleted after the update,
            * update its data. 
            * Else set it as undefined (it has been deleted)
            */
            if(this.state.selected_procedure !== undefined){
                let prevProcedure = this.state.selected_procedure.id;
                let updatedProcedure = this.state.procedures.find(obj => obj.id === prevProcedure);
                if(updatedProcedure !== undefined){
                        this.setState({selected_procedure : updatedProcedure});
                    }
                    else{
                        this.setState({selected_procedure : undefined});
                    }
                }
            // Update Statistics
            this.refreshStatistics();
        })
        .catch(err => {
            errorHandler(err)
            this.setState({ loading: false });
        });
    }

    // Every time user adds or deletes a procedure this function triggers and refresh statistics
    refreshStatistics(){
        API.get("ctrl/stats/", {
            headers : {
                "Authorization": "Token " + localStorage.getItem('token')}
            }
        )
        .then(res => {
            //Creates a temporary array and modify it with our new data
            let newStats = this.state.stats;

            newStats[0].number = res.data.procedures.total;
            newStats[1].number = res.data.procedures.active;
            newStats[2].number = res.data.procedures.completed;
            newStats[3].number = res.data.procedures.archived;

            newStats[4].number = res.data.projects.total;
            newStats[5].number = res.data.projects.active;
            newStats[6].number = res.data.projects.inactive;

            //Then pass it as a state
            this.setState({stats : newStats})
        })

    }

    refreshProjectList(){
        API.get('projects/owned_projects/',{
            headers : {
                "Authorization": "Token " + localStorage.getItem('token')
            }
        })
        .then(res => {
            this.setState({projects: res.data}, () => {
                // Delete default project from project list
                if(this.state.projects[0].default){
                    let temp = this.state.projects;
                    temp.shift()
                    this.setState({
                        projects : temp
                    })
                }
            })
        })
        .catch(err => errorHandler(err));
    }

    onShowHideArchived(checked){
        this.setState({ showArchived : checked })
    }


    render() {
        return (
        <Animate
                transitionName="fade"
                transitionAppear
            >
            <Layout hasSider={true}>
            <div className="container">

                {/* Master Row*/}
                <Row type="flex" gutter={[16,0]} style={{ marginLeft : "15px", marginRight : "15px", marginTop:"15px"}}> 

                    {/* First Column*/}
                    <Col span={4} xl={3}>
                            <NavLink className="movin" to='/create' >
                                <Button type="primary" size="large" block={true} className={Array.isArray(this.state.procedures) && !this.state.procedures.length && "glow-btn"}>Προσθήκη</Button>
                            </NavLink>

                            <Divider/>
                            
                            <Statistics stats={this.state.stats} refreshStatistics={this.refreshStatistics}></Statistics>
                            
                            <Divider/>

                            <Row type="flex" justify="center">
                                <Text strong style={{textAlign: "center", fontSize : "16px", marginTop : "-12px"}}>Επιλογή Έργου</Text>
                            </Row>

                                <ProjectList 
                                    selected_project={this.state.selected_project} 
                                    projects={this.state.projects.filter(function(value){ return value.active})} 
                                    refreshProjectList={this.refreshProjectList} 
                                    handleProjectSelection={this.handleProjectSelection}>
                                </ProjectList>

                            <Divider/>

                            <Row type="flex" justify="center">
                                <Text strong style={{ textAlign : "center" }}>Εμφάνιση Αρχειοθετημένων Διαδικασιών</Text>
                                <Switch style={{ marginTop : "10px"}} onChange={this.onShowHideArchived}/>
                            </Row>

                            <Divider/>
                    </Col>

                    {/* Second Column, Procedure List*/}
                    <Col span={6} style={{marginTop : "5px"}}>
                        <ProcedureList 
                            handleProcedureSelection={this.handleProcedureSelection} 
                            procedures={this.state.procedures} 
                            selected_procedure={this.state.selected_procedure} 
                            selected_project={this.state.selected_project}
                            archived={this.state.showArchived}>
                        </ProcedureList>
                    </Col>

                    {/* Third Column, Procedure Details Window*/}
                    <Col span={14} xl={15}>
                        <ProcedureDetails 
                            selected_procedure={this.state.selected_procedure} 
                            refreshProcedureList={this.refreshProcedureList} 
                            refreshProjectList={this.refreshProjectList}>
                        </ProcedureDetails>
                    </Col>

                </Row> 
            </div>
            </Layout>
        </Animate>
        )
    }
}