import React, { Component } from 'react'
import '../_styles/Dashboard.scss'
import {Button, Progress, Skeleton, Popconfirm, Alert, message, Row, Col, Tooltip, Icon} from 'antd';
import API from '../../utils/API';
import {errorHandler} from '../../utils/errorHandler';
import ProjectModal from './ViewProjectModal'
import ProcedureModal from './EditProcedureModal'
import moment from 'moment'


export default class ProcedureTitle extends Component {

    constructor(props) {
        super(props);

        this.state = {
            projectModalVisible: false,
            procedureModalVisible: false,
            smallScreenResolution : window.innerWidth <= 1280
        }
        
        this.handleArchiveProcedure = this.handleArchiveProcedure.bind(this);
        this.handleRestoreProcedure = this.handleRestoreProcedure.bind(this);
        this.projectModalVisible = this.projectModalVisible.bind(this);
        this.showProjectModal = this.showProjectModal.bind(this);
        this.procedureModalVisible = this.procedureModalVisible.bind(this);
        this.showProcedureModal = this.showProcedureModal.bind(this);
    }


    // Updates Title, Procedure Code and Date when new props arrive
    componentDidUpdate(prevProps){
        if(prevProps !== this.props){
            if(this.props.selected_procedure === undefined)
                this.setState({
                    title : "",
                    distinct_procedure : "",
                    creation_date : "",
                    progress : 0,
                    smallScreenResolution : window.innerWidth <= 1280
                });
        else
            this.setState({
                title : this.props.selected_procedure.title,
                distinct_procedure : this.props.selected_procedure.distinct_procedure,
                creation_date : this.props.selected_procedure.creation_date,
                progress : this.props.selected_procedure.progress,
                smallScreenResolution : window.innerWidth <= 1280
                
            });
        }
    }


    // Parsing date : format DD/MM/YYYY
    parseDate(){
        let str = this.state.creation_date;

        if(str){
            let parts = str.slice(0, -1).split('T');
            let dateComponent = parts[0];
            return dateComponent;
        }
      }

    // Delete's (make invisible) a procedure.
    handleArchiveProcedure(){
            API.patch("user_procedures/" + this.props.selected_procedure.id + "/", {
                visible : false
            }, {
                headers : {"Authorization": "Token " + localStorage.getItem('token')}
            })
            .then(res => {
                message.success('Η διαδικασία αρχειοθετήθηκε επιτυχώς');
                this.props.refreshProcedureList();
            })
            .catch(err => errorHandler(err))
    }

    handleRestoreProcedure(){
        API.patch("user_procedures/" + this.props.selected_procedure.id + "/", {
            visible : true
        }, {
            headers : {"Authorization": "Token " + localStorage.getItem('token')}
        })
        .then(res => {
            message.success('Η διαδικασία επαναφέρθηκε επιτυχώς');
            this.props.refreshProcedureList();
        })
        .catch(err => errorHandler(err))
    }

    getProject(){
        if(this.props.selected_procedure !== null){
            return this.props.selected_procedure.project
        }
        else return undefined
    }

    projectModalVisible(visible) {
        this.setState({ projectModalVisible: visible });
    }

    showProjectModal() {
        this.projectModalVisible(true);
    }

    procedureModalVisible(visible) {
        this.setState({ procedureModalVisible: visible });
    }

    showProcedureModal() {
        this.procedureModalVisible(true);
    }

    showEditDeleteButtons(){
        if(this.state.smallScreenResolution)
            return <Col span={2}>
                        <Tooltip title="Επεξεργασία Διαδικασίας">
                            <Button
                                type="primary"
                                icon="edit" 
                                onClick={this.showProcedureModal}
                                style={{ marginBottom : "15px" }}
                                >
                            </Button>
                        </Tooltip>
                        <Tooltip title="Προβολή Έργου">
                            <Button
                                type="danger"
                                icon="eye" 
                                onClick={this.showProjectModal}
                                style={{ marginTop: "15px" }}
                                >
                            </Button>
                        </Tooltip>
                    </Col>;
        else
            return <Col span={6}>
                        <Button 
                            block 
                            type="primary"
                            onClick={this.showProcedureModal}
                            style={{ marginBottom : "15px" }}
                            >
                                Επεξεργασία Διαδικασίας
                        </Button>
                        <Button 
                            block 
                            type="danger"
                            onClick={this.showProjectModal}
                            style={{ marginTop: "15px" }}
                            >
                                Προβολή Έργου
                        </Button>
                    </Col>
    }

    render(){
        // If there's no selected procedure,
        // set a skeleton effect, as well as a guidance field to help user take action.
        if(this.props.selected_procedure===undefined)
            return (
                <div style={{marginTop : "14px"}}>
                    <Alert message="Παρακαλώ επιλέξτε ή ξεκινήστε μία διαδικασία." type="info" showIcon />
                    <Alert message="Για να ξεκινήσετε μία νέα διαδικασία πατήστε το μεγάλο κουμπί 'Προσθήκης' πάνω αριστερά." type="info" showIcon />
                    <Skeleton />
                </div>
            )
        else
            return (
                <Row type="flex" align="middle" gutter={[16,0]}>

                    <ProjectModal
                            visible={this.state.projectModalVisible}
                            setVisible={this.projectModalVisible}
                            project={this.getProject()}
                            refreshProjectList={this.props.refreshProjectList}
                            owned={this.props.selected_procedure.owned}
                        />
                    
                    <ProcedureModal
                            visible={this.state.procedureModalVisible}
                            setVisible={this.procedureModalVisible}
                            selected_procedure={this.props.selected_procedure}
                            refreshProcedureList={this.props.refreshProcedureList}
                        />

                    {this.showEditDeleteButtons()}

                    <Col span={this.state.smallScreenResolution ? 18 : 12}> 


                        {/*Title Container*/}
                        <div>
                            <span className="title-text">{this.state.title}</span>
                            <div>{this.state.distinct_procedure}</div>

                            <Progress percent={this.state.progress} />

                            <hr className="hr2" />

                            {/*Title Footer*/}     
                            <div className="under-title-container">
                                <div>
                                    {moment(this.state.creation_date).locale('el').format('LLLL') + "  "}
                                    <Tooltip title="Ημερομηνία Δημιουργίας">
                                        <Icon type="info-circle" theme="twoTone" />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>

                    </Col>

                    {/*Archive Procedure Button */}
                    {this.props.selected_procedure.owned && 
                        <Col span={this.state.smallScreenResolution ? 2 : 6}>
                            {this.props.selected_procedure.visible ?
                                <Tooltip title={this.state.smallScreenResolution && "Αρχειοθέτηση Διαδικασίας"}>
                                    <Popconfirm
                                        placement="bottom"
                                        title={'Είσαι σίγουρος ότι θέλεις να αρχειοθετήσεις την διαδικασία "' + this.props.selected_procedure.title + '";'}
                                        onConfirm={this.handleArchiveProcedure}
                                        okText="Αρχειοθέτηση"
                                        cancelText="Ακύρωση">
                                            <Button 
                                                block={!this.state.smallScreenResolution} 
                                                type="danger" 
                                                icon="container" 
                                                ghost>{!this.state.smallScreenResolution && "Αρχειοθέτηση Διαδικασίας"}
                                            </Button>
                                    </Popconfirm>
                                </Tooltip>
                            :
                                <Tooltip title={this.state.smallScreenResolution && "Επαναφορά Διαδικασίας"}>
                                    <Button block={!this.state.smallScreenResolution} icon="rollback" onClick={this.handleRestoreProcedure}>{!this.state.smallScreenResolution && "Επαναφορά Διαδικασίας"}</Button>
                                </Tooltip>
                            }
                        </Col>
                    }
                </Row>    
            )
    }
}
