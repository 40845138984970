import React, { Component } from 'react'
import '../_styles/Dashboard.scss'
import {Button, List, Skeleton, message, Popconfirm, Tabs, Icon, Typography, Row, Col, Divider} from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import API from '../../utils/API';
import {errorHandler} from '../../utils/errorHandler';

const { TabPane } = Tabs;

export default class DocumentsWindow extends Component {

    constructor(props){
        super(props);

        // stepFiles is a list with user's file's for a given step of a procedure.
        // procFiles is a list with user's file's for a given procedure.
        this.state = {
            selectedProcedure : undefined,
            procFiles : undefined,
            stepFiles : undefined,
            selectedFile : null,
            loading : false
        }


        this.onFileChangeHandler = this.onFileChangeHandler.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.filterDocumentsByStep = this.filterDocumentsByStep.bind(this);
        this.refreshDocuments = this.refreshDocuments.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.getFileList = this.getJSXFileList.bind(this);
    }


    componentDidUpdate(prevProps){
        // If selected procedure changes, refresh document list.
        let prev = prevProps.selected_procedure
        let curr = this.props.selected_procedure
        if((prev !== curr && curr !== undefined) || (prevProps.selected_step !== this.props.selected_step)){
            this.setState({
                selectedProcedure : this.props.selected_procedure
            },
            this.refreshDocuments);
        }
    }

    // When user selects a new file, this method informs selectedFile state.
    onFileChangeHandler = event => {
        let fileSize = event.target.files[0].size;
        let fileSizeInMB = fileSize/1024/1024;

        if (fileSizeInMB > 5) {
            message.error('Αρχεία μεγαλύτερα από 5MB δεν επιτρέπονται')
            return
        }

        this.setState({
          selectedFile: event.target.files[0],
          loaded: 0,
        })
    }

    // Upload. Takes the selected file and turns it in formdata type so API can read it and store it.
    onUpload = () => {
        const hide = message.loading('Το αρχείο ανεβαίνει...', 0);
        const data = new FormData();
        data.append('file', this.state.selectedFile);
        data.append('project', this.state.selectedProcedure.project);
        data.append('project_procedure', this.state.selectedProcedure.id);
        data.append('step', this.state.selectedProcedure.steps_completed - this.props.selected_step + 1);

        // Post form data to API.
        API.post("user_files/", data ,
        {
            headers : {
                'Content-Type': 'multipart/form-data',
                "Authorization": "Token " + localStorage.getItem('token'),
            }
        })
        .then(res => {
            // Refresh both documents' list
            this.refreshDocuments();
            // Loading dismiss manually and asynchronously
            setTimeout(hide, 0);
            message.success('Επιτυχής μεταφόρτωση.');
        })
        .catch(err => {
            setTimeout(hide, 0)
            errorHandler(err)
        });
    }

    // Refreshes the two state file lists everytime something updates.
    refreshDocuments(){

        // Let Skeleton inform user that smth its loading
        this.setState({loading : true});

        // API call for user's files filtered by the given procedure
        API.get("user_files/?project_procedure=" + this.state.selectedProcedure.id, {
            headers : {"Authorization": "Token " + localStorage.getItem('token')}
            })
            .then(res => {
                this.setState({procFiles : res.data}, () => this.filterDocumentsByStep())
            })
            .catch(err => errorHandler(err))
    }

    filterDocumentsByStep(){
        let stepDocuments
        stepDocuments = this.state.procFiles.filter((document) => {
            return document.step === this.state.selectedProcedure.steps_completed - this.props.selected_step + 1;
        })
        this.setState({ stepFiles : stepDocuments })
        this.setState({ loading : false })
    }

    // Delete. Takes a file as a parameter and deletes it.
    deleteFile(file){
        const hide = message.loading('Το αρχείο ' + file.name + ' διαγράφεται...', 0);
        API.delete("user_files/" + file.id,{
            headers : {"Authorization": "Token " + localStorage.getItem('token')}
        },{
            project_procedure : file.project_procedure,
            step : file.step
        })
        .then(res => {
            this.refreshDocuments();
            setTimeout(hide, 0);
            message.success('Επιτυχής διαγραφή.');
        })
        .catch(err => errorHandler(err))
    }


    // Download. Takes a file as a parameter and downloads it.
    downloadFile = (file) => {

        const hide = message.loading('Το αρχείο ' + file.name + ' προετοιμάζεται...', 0);

        API.get('user_files/'+ file.id+'/download/',{
            headers : {"Authorization": "Token " + localStorage.getItem('token')},
            responseType:"blob"
        })
        .then(res => {
            // We take a file as a response, so we have to trasform it to Blob type...
            // so it can be downloadable.
            const blob = new Blob([res.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.name); //or any other extension
            document.body.appendChild(link);
            // Download trigger.
            setTimeout(hide, 0);
            message.success('Το αρχείο ' + file.name + ' είναι έτοιμο για κατέβασμα.');
            link.click();
        })
        .catch(err => errorHandler(err))
    }

    // Returns a file list (in JSX) with its data based on the given parameter.
    getJSXFileList(files){
        return (
            <div className="scroll-list2">
                <List
                size="large"
                bordered = {false}
                dataSource={files}
                renderItem={item => 
                    <Row>
                        <List.Item>
                                <Col span={6} style={{ textAlign: "center" }}>
                                    <Icon style={{ fontSize : "30px" }} type={this.getFileIcon(item.file)} theme="twoTone" />
                                </Col>
                                <Col span={12}>
                                    <Typography.Text ellipsis style={{ maxWidth : "100%"}} >{item.name}</Typography.Text>
                                </Col>
                                <Col span={6} style={{ textAlign: "center" }}>
                                    <ButtonGroup>
                                        {/* Download File Button */}
                                        <Button type="primary" icon="download" onClick={() => this.downloadFile(item)}/>

                                        {/* Delete Confirmation Window */}
                                        <Popconfirm
                                            title={'Είσαι σίγουρος ότι θέλεις να διαγράψεις το αρχείο "' + item.name + '";'}
                                            onConfirm={() => this.deleteFile(item)}
                                            okText="Διαγραφή"
                                            cancelText="Ακύρωση">

                                            {/* Delete File Button */}
                                            <Button type="danger" icon="delete"/>
                                        </Popconfirm>
                                    </ButtonGroup>
                                </Col>
                        </List.Item>
                    </Row>}
                />
            </div>
        );
    }

    getFileIcon(name){
        if(name.substring(name.length - 3) === "pdf")
            return "file-pdf"
        else if(name.substring(name.length - 4) === "docx" || name.substring(name.length - 3) === "doc")
            return "file-word"
        else if(name.substring(name.length - 3) === "jpg" || name.substring(name.length - 4) === "jpeg" || name.substring(name.length - 3) === "png")
            return "file-image"
        else if(name.substring(name.length - 3) === "xls" || name.substring(name.length - 4) === "xlsx")
            return "file-excel"
        else if(name.substring(name.length - 3) === "rar" || name.substring(name.length  -3) === "zip" || name.substring(name.length - 2) === "7z")
            return "file-zip"
        else
            return "file-text"
    }

    render() {
            return (  
                <Row className="box-container" align={"middle"} style={{ marginTop : "10px", marginBottom : "10px"}}>
                    <div className="header">Δικαιολογητικά</div>

                    <hr className="hr2"></hr>

                    {/* File list tabs */}
                    {this.state.selectedProcedure === undefined || this.state.loading ?
                        <Skeleton active={this.state.loading}></Skeleton>

                    :
                
                    <Tabs defaultActiveKey="1" type="card">
                        <TabPane tab="Διαδικασίας" key="1">
                            {this.getJSXFileList(this.state.procFiles)}
                        </TabPane>
                        <TabPane tab="Βήματος" key="2">
                            {this.getJSXFileList(this.state.stepFiles)}
                        </TabPane>
                    </Tabs>
                    
                    }

                    {/* Upload Session */}
                    <Divider/>

                    <input 
                        type="file"
                        name="file"
                        onChange={this.onFileChangeHandler}
                        accept=".pdf,.png,.jpeg,.jpg,.zip,.rar,.7z,.xlsx,.xls,.doc,.docx"    
                    ></input>

                    <Divider/>

                    <div style={{ textAlign : "center", margin : "10px"}}>
                        <Button 
                            type="primary" 
                            disabled={this.state.selectedProcedure === undefined || 
                                !this.state.selectedProcedure.visible || 
                                this.state.selectedFile === null} 
                            icon="upload" 
                            onClick={this.onUpload}>
                                Ανέβασμα αρχείου
                        </Button>
                    </div>
                </Row>
        )
    }
}