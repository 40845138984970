import { message, Icon, Form, Input, Button, Select } from 'antd';
import React from 'react';
import '../../_styles/_shared.scss'
import API from '../../../utils/API'
import { ntwrk_err } from '../../_shared/messages';

const { Option } = Select;

class ProfileForm extends React.Component {
    
	constructor(props){
		super(props);

		this.state = {
            options: null,
            loading: true,
            selectedAvatar: null
        }
        
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount(){
        this.getOrgChoices()
    }

    getOrgChoices = () => {
        API.get("org_choices/")
		.then(res => {
            this.setState(
                {
                    options: res.data["choices"],
                    loading: false
                }
            )
		})
    }

    getOptions = () => {
        if(this.state.options !== undefined){
            let options = this.state.options.map((e, i) => 
                <Option key={i} value={e[0]}>{e[1]}</Option>
            )

            return options
        }
    }

    handleSubmit = () => {

        this.props.form.validateFields((e) => {
            if(!e){
                const data = new FormData()
                data.append("first_name", this.props.form.getFieldValue("first_name"))
                data.append("last_name", this.props.form.getFieldValue("last_name"))
                data.append("email", this.props.form.getFieldValue("email"))
                data.append("profile.organization", this.props.form.getFieldValue("organization"))
                if(this.state.selectedAvatar != null)
                    data.append("profile.avatar", this.state.selectedAvatar)
        
                API.patch("users/" + this.props.user.id + "/", 
                data,
                {
                    headers : {
                        "Authorization": "Token " + localStorage.getItem('token'),
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(r => {
                    message.success("Επιτυχής ανανέωση στοιχείων")
                    this.props.getProfile()
                })
                .catch(e => {
                    ntwrk_err()
                })
            } else {
                message.error("Ελέγξτε τα πεδία")
            }
        })

    }

    handleSelectAvatar = (e) => {
        this.setState({selectedAvatar: e.target.files[0]})
    }

    
	render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="flex-container" style={{marginTop: "5em"}}>
                {!this.state.loading &&
                    <Form>
                        <Form.Item>
                            {getFieldDecorator('first_name', {
                                initialValue: this.props.user.first_name
                            })(
                                <Input
                                    prefix={<Icon type="user" />}
                                    placeholder="Όνομα"
                                    name="first_name"
                                ></Input>
                            )}
                        </Form.Item>

                        <Form.Item>
                            {getFieldDecorator('last_name', {
                                initialValue: this.props.user.last_name
                            })(
                                <Input
                                    prefix={<Icon type="user" />}
                                    placeholder="Επίθετο"
                                    name="last_name"
                                ></Input>
                            )}
                        </Form.Item>

                        <Form.Item>
                            {getFieldDecorator('email', {
                                initialValue: this.props.user.email,
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'Μη-έγκυρο e-mail!',
                                    }, 
                                    {
                                        required: true, message: 'Παρακαλώ εισάγετε το e-mail σας!',
                                    }]
                                })(
                                    <Input
                                        name="email"
                                        onChange={this.handleChange}
                                        prefix={<Icon type="mail"/>}
                                    />
                                )}
                        </Form.Item>

                        <Form.Item>
                            {getFieldDecorator('organization', {
                                initialValue: this.props.user.profile.organization
                            })(
                                <Select
                                    placeholder="Organization"
                                >
                                    {this.getOptions()}
                                </Select>
                            )}
                        </Form.Item>
                        
                        <Form.Item>
                            <label htmlFor="fileup">Ανανέωση φωτογραφίας προφίλ</label>
                            <input
                                id="fileup"
                                type="file"
                                name="avatar"
                                accept=".png,.jpg"
                                onChange={this.handleSelectAvatar}
                            />
                        </Form.Item>
                        
                        <Form.Item>
                            <Button type="primary" onClick={this.handleSubmit}>
                                Ενημέρωση
                            </Button>
                        </Form.Item>
                    </Form>
                }
            </div>
        )
    }

}

const WrappedForm = Form.create()(ProfileForm)
export default WrappedForm