import { message } from 'antd';


export const ntwrk_err = () => {
    message.error("Σφάλμα δικτύου.");
}

export const succ_register = () => {
    message.success("Επιτυχής εγγραφή")
}

export const succ_login = () => {
    message.success("Επιτυχής σύνδεση");
};

export const succ_logout = () => {
    message.success("Επιτυχής αποσύνδεση");
};