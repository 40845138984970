import { Modal, Collapse, Icon } from 'antd'
import  React, { Component }  from 'react'
import NoteTextModal from './NoteTextModal'

const { Panel } = Collapse;

export default class NoteModalMaster extends Component {

    constructor(props) {
        super(props);

        this.state = {
            textModalVisible: false,
            focusedStep: null
        }
        
        this.textModalVisible = this.textModalVisible.bind(this);
        this.showTextModal = this.showTextModal.bind(this);
    }

    handleOk = e => {
        this.props.setVisible(false);
    };

    handleCancel = e => {
        this.props.setVisible(false);
    };

    textModalVisible(visible) {
        this.setState({ textModalVisible: visible });
    }

    showTextModal(step) {
        this.setState({ focusedStep: step });
        this.textModalVisible(true);
    }

    getPanels() {
        if(this.props.data !== [] && this.props.data !== undefined) {
            let panels = this.props.data.map((v => 
                <Panel
                header={v.title}
                key={v.id}
                extra =
                    {v.note !== null ?
                            <Icon
                                type="edit"
                                onClick={() => this.showTextModal(v)}
                                style={{marginTop: ".5em", display: "block"}}
                            />
                    :
                            <Icon
                                type="plus"
                                onClick={() => this.showTextModal(v)}
                                style={{marginTop: ".5em", display: "block"}}
                            />
                    }
                >
                    {v.note !== null ?
                        <pre>{v.note}</pre>
                    :
                        <p>Δεν υπάρχει σημείωση για αυτό το βήμα.</p>
                    }
                </Panel>
            ));

            return panels;
        }
    }

    render() {
        return (
            <Modal
                title="Σημειώσεις Χρήστη"
                visible={this.props.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okText="ΟΚ"
                cancelText="Άκυρο"
            >
                <NoteTextModal
                    visible={this.state.textModalVisible}
                    setVisible={this.textModalVisible}
                    step={this.state.focusedStep}
                    selected_procedure={this.props.selected_procedure}
                    updateStep={this.props.updateStep}
                />

                <Collapse bordered={false} style={{backgroundColor: "white"}}>
                    {this.getPanels()}
                </Collapse>
            </Modal>
        );
    }
}
