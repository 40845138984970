import React, { Component } from 'react'
import {Select, Button, Row} from 'antd';
import ProjectModal from './EditProjectModal'

const { Option } = Select;

export default class ProjectList extends Component {

    constructor(props){

        super(props);

        this.state = { 
            selectedProject : undefined,
            smallScreenResolution : window.innerWidth <= 1800
         };

        this.onChange = this.onChange.bind(this);

        this.projectModalVisible = this.projectModalVisible.bind(this);
        this.showProjectModal = this.showProjectModal.bind(this);
    }

    componentDidMount(){
        this.props.refreshProjectList();
    }

    onChange(value) {
        var project = (value === "default" ? undefined : value)

        this.setState({selectedProject : project})
        this.props.handleProjectSelection(project)
    }

    projectModalVisible(visible) {
        this.setState({ projectModalVisible: visible });
        if(!visible){
            this.props.refreshProjectList();
        }
    }

    showProjectModal() {
        this.projectModalVisible(true);
    }
    

    getProject(){
        let selected = {};

        selected = this.props.projects.find(element => element.id === this.state.selectedProject);

        return(selected === undefined ? {} : selected)
    }

    render() {
        return (
            <div className="flex-column">
                <ProjectModal
                    visible={this.state.projectModalVisible}
                    setVisible={this.projectModalVisible}
                    project={this.getProject()}>
                </ProjectModal>
                <Select defaultValue="default" onChange={this.onChange} style={{marginTop : "10px"}}>
                <Option value="default">Όλα</Option>

                {this.props.projects.map((item, i) => 
                    <Option key={item.id} value={item.id}>{item.title}</Option>
                )}
                </Select>
                <Row type="flex" align={"middle"} justify={"space-around"}>
                    <Button
                        block
                        type="danger"
                        icon="edit"
                        disabled={this.state.selectedProject === undefined} 
                        style={{marginTop : "10px"}} 
                        onClick={this.showProjectModal}>
                            {!this.state.smallScreenResolution && "Επεξεργασία Έργου"}
                    </Button>
                </Row>
            </div>
            
        )
    }
}
