import React, { Component } from 'react'
import {Row, Col, Divider} from 'antd';
import '../_styles/Dashboard.scss'

import CurrentStep from './CurrentStepWindow'
import DocumentsWindow from './DocumentsWindow'
import ProcedureTitle from './ProcedureTitle'
import TimelineWindow from './TimelineWindow'

/* This is the control panel of the whole right side of our dashboard.
* This component receives data from Dashboard component and
* has to spread that updated info to its' children.
*/
export default class ProcedureDetails extends Component {
    
    constructor(props){
        super(props)

        this.state = {
            selected_procedure : undefined,
            selected_step : 0
        }

        this.onPreviousStep = this.onPreviousStep.bind(this);
        this.onNextStep = this.onNextStep.bind(this);
        this.resetStep = this.resetStep.bind(this);


    }

    onPreviousStep(){
        if(this.props.selected_procedure.steps_completed !== this.state.selected_step)
            this.setState({ selected_step : this.state.selected_step + 1})
    }
    
    onNextStep(){
        this.setState({ selected_step : this.state.selected_step - 1})
    }

    resetStep(){
        this.setState({ selected_step : 0});
    }

    // Refresh Selected Procedure state everytime an update happens
    componentDidUpdate(prevProps){
        if(prevProps.selected_procedure !== this.props.selected_procedure){
            this.setState({selected_procedure : this.props.selected_procedure}, () => this.resetStep());
        }

    }

    render() {
        return (
            <Col>

                {/* Procedure Title*/}
                <ProcedureTitle
                    selected_procedure={this.state.selected_procedure}
                    refreshProcedureList={this.props.refreshProcedureList}
                    refreshProjectList={this.props.refreshProjectList}>
                </ProcedureTitle>

                <Divider/>
                
                <Row type="flex">
                   
                    {/* Fourth Column. Next Step Window and Files*/}
                    <Col span={10}>

                        {/* Current Step Window*/}
                        <CurrentStep 
                            selected_procedure={this.state.selected_procedure} 
                            refreshProcedureList={this.props.refreshProcedureList} 
                            selected_step={this.state.selected_step} 
                            onPreviousStep={this.onPreviousStep} 
                            onNextStep={this.onNextStep}
                            resetStep={this.resetStep}>
                        </CurrentStep>

                        {/* Files Window*/}
                        <DocumentsWindow 
                            selected_procedure={this.state.selected_procedure} 
                            refreshProcedureList={this.props.refreshProcedureList} 
                            selected_step={this.state.selected_step}>
                        </DocumentsWindow>
                        
                    </Col>

                    {/* Timeline Window*/}
                    <Col span={14}>
                        <TimelineWindow selected_procedure={this.state.selected_procedure}/>
                    </Col>

                </Row>
            </Col>
        )
    }
}
